import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Segment, Container, Header, Form, Input, Label, Icon, Button, Placeholder } from 'semantic-ui-react'
import { append, dateLocales, debounce, extractHostFromUrlOrEmailAddress, formatNumber, handleRedirect, isObject, isProbablyFullyQualifiedDomainName, navigate, preventDefault, rtrim } from '../../helpers'
import { createDomain, getDomainRegistrarServiceByName } from '../../api/domains'
import { getCompanies, TYPE_CUSTOMER as COMPANY_TYPE_CUSTOMER } from '../../api/autotask/companies'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { useMessages } from '../root'
import TaskExecutionLink from '../../components/task_execution_link'
import DataTable from '../../components/data_table'
import DomainContact, { contactTypeIndividual, contactTypeOrganization, domainContactIsValid, noridIdentityTypeOrganization, noridIdentityTypePerson } from '../../components/domain_contact'
import DomainContactSelect, { defaultData as defaultContactData } from '../../components/domain_contact_select'
import NameserversInput from '../../components/nameservers_input'
import DomainRegistrarServiceCard from '../../components/domain_registrar_service_card'
import Link from '../../components/link'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { PERIOD_TYPE_ONE_TIME as PRODUCT_PERIOD_TYPE_ONE_TIME, PERIOD_TYPE_YEARLY as PRODUCT_PERIOD_TYPE_YEARLY, CATEGORY_DOMAIN_NAME as PRODUCT_CATEGORY_DOMAIN_NAME, CATEGORY_DOMAIN_TRANSFER as PRODUCT_CATEGORY_DOMAIN_TRANSFER, getProducts } from '../../api/autotask/products'
import { getContacts } from '../../api/autotask/contacts'
import { getCountry } from '../../api/autotask/countries'

export async function loader({ request }) {
	const url = new URL(request.url)
	let accountId = url.searchParams.get('account_id')
	if (typeof accountId !== 'string' || accountId.trim().length === 0) accountId = null
	return { accountId }
}

const initialCompanyQuery = [
	{field: 'isActive', op: 'eq', value: true},
	{field: 'companyType', op: 'eq', value: COMPANY_TYPE_CUSTOMER},
	{field: 'companyNumber', op: 'noteq', value: '0'},
	{field: 'companyNumber', op: 'noteq', value: 'AUTO'},
	{field: 'KitCloud UUID', op: 'exist', udf: true},
]

const transferCodeTypeAuthInfo = 'authinfo'
const transferCodeTypeNoridToken = 'norid_token'

const defaultNameservers = [
	'ns1.kitdns.no',
	'ns2.kitdns.no',
	'ns3.kitdns.no',
]

const bindConfigParkingNoMailBlock = ";; Domeneparkering\n\n@\tIN\tCNAME\tparking.kitcloud.no.\nwww\tIN\tCNAME\t@\n";
const bindConfigParking = bindConfigParkingNoMailBlock + "@\tIN\tTXT\t\"v=spf1 -all\"\n*._domainkey\tIN\tTXT\t\"v=DKIM1;p=\"\n_dmarc\tIN\tTXT\t\"v=DMARC1;p=reject;sp=reject;adkim=s;aspf=s;\"\n";

const applicantDeclarationDocsUrl = "https://teknisk.norid.no/no/administrere-domenenavn/egenerklaering-fra-abonnenten/kravspesifikasjon-egenerklaering/"

export default function NewDomainPage() {
	const [, setMessages] = useMessages()
	const { accountId } = useLoaderData()

	const routerNavigateFn = useNavigate()

	const updateDataAbortController = useRef(null)

	const [loadingRegistrarService, setLoadingRegistrarService] = useState(false)
	const [registrarService, setRegistrarService] = useState(null)

	const [selectedCompany, setSelectedCompany] = useState(null)
	const [loadingCompanyCountry, setLoadingCompanyCountry] = useState(null)
	const [companyCountry, setCompanyCountry] = useState(null)
	const [loadingPrimaryContact, setLoadingPrimaryContact] = useState(null)
	const [primaryContact, setPrimaryContact] = useState(null)

	const [transferCodeType, setTransferCodeType] = useState(transferCodeTypeAuthInfo)
	const [transferCode, setTransferCode] = useState('')

	const [ownerContact, setOwnerContact] = useState(defaultContactData)

	const [managedDNS, setManagedDNS] = useState(true)
	const [customNameservers, setCustomNameservers] = useState([])

	const [bindConfig, setBindConfig] = useState(bindConfigParking)

	const [applicantDeclarationDate, setApplicantDeclarationDate] = useState(new Date())
	const [applicantDeclarationVersion, setApplicantDeclarationVersion] = useState('3.2')
	const [applicantDeclarationName, setApplicantDeclarationName] = useState('')

	const [loadingProducts, setLoadingProducts] = useState(false)
	const [domainProduct, setDomainProduct] = useState(null)
	const [transferProduct, setTransferProduct] = useState(null)
	const [domainUnitPrice, setDomainUnitPrice] = useState(null)
	const [transferUnitPrice, setTransferUnitPrice] = useState(null)

	const [creatingDomain, setCreatingDomain] = useState(false)
	const [taskExecutionId, setTaskExecutionId] = useState(null)

	const domainNameParts = isObject(registrarService) &&
		isObject(registrarService.domain_name_parts)
			? registrarService.domain_name_parts
			: null

	const registry = isObject(domainNameParts) && domainNameParts.tld === 'no'
		? 'NORID'
		: 'ASCIO'

	const isTransfer = isObject(registrarService) &&
		isObject(registrarService.whois) &&
		registrarService.whois.registered

	const autotaskDomainContact = useMemo(() => isObject(selectedCompany) ? {
		id: '_autotaskCompany',
		account_id: null,
		handles: [],
		country: {
			iso2: isObject(companyCountry) ? companyCountry.countryCode : null,
			name: isObject(companyCountry) ? companyCountry.name : null,
		},
		name: isObject(primaryContact) ? [
			primaryContact.firstName,
			primaryContact.middleInitial,
			primaryContact.lastName,
		].filter(v => typeof v === 'string' && v.length > 0).join(' ').replace(/\s+/g, ' ') : (typeof selectedCompany.taxID === 'string' && selectedCompany.taxID.length > 0 ? null : selectedCompany.companyName),
		phone: { number: selectedCompany.phone, uri: null },
		fax: { number: selectedCompany.fax, uri: null },
		email_address: isObject(selectedCompany.userDefinedFields) && selectedCompany.userDefinedFields.hasOwnProperty('Email') ? selectedCompany.userDefinedFields['Email'] : null,
		city: selectedCompany.city,
		state: selectedCompany.state,
		street_address: selectedCompany.address1,
		postal_code: selectedCompany.postalCode,
		norid_identity_type: isObject(domainNameParts) && domainNameParts.tld === 'no' ? (typeof selectedCompany.taxID === 'string' && selectedCompany.taxID.length > 0 ? noridIdentityTypeOrganization : noridIdentityTypePerson) : null,
		norid_identity: isObject(domainNameParts) && domainNameParts.tld === 'no' && typeof selectedCompany.taxID === 'string' && selectedCompany.taxID.length > 0 ? selectedCompany.taxID : null,
		contact_type: typeof selectedCompany.taxID === 'string' && selectedCompany.taxID.length > 0 ? contactTypeOrganization : contactTypeIndividual,
		organization_name: typeof selectedCompany.taxID === 'string' && selectedCompany.taxID.length > 0 ? selectedCompany.companyName : null,
		organization_number: isObject(domainNameParts) && domainNameParts.tld !== 'no' ? selectedCompany.taxID : null,
		id_card_number: null,
		created_at: null,
		updated_at: null,
	} : null, [companyCountry, domainNameParts, primaryContact, selectedCompany])

	// debounced user input: domain input field
	const initialDomainNameInput = ''
	const [domainNameInputError, setDomainNameInputError] = useState(null)
	const [domainNameInput, setDomainNameInput] = useState(initialDomainNameInput)
	const [debouncedTransformedDomainNameInput, setDebouncedTransformedDomainNameInput] = useState(initialDomainNameInput)
	const debouncedSetDebouncedTransformedDomainNameInput = useMemo(() => debounce(value => {
		const transformedValue = rtrim(extractHostFromUrlOrEmailAddress(value), '.')
		setDebouncedTransformedDomainNameInput(transformedValue)
	}, 500), [])
	useEffect(() => debouncedSetDebouncedTransformedDomainNameInput(domainNameInput), [domainNameInput, debouncedSetDebouncedTransformedDomainNameInput])
	const cachedTransformedDomainNameInput = useRef(initialDomainNameInput)

	// domain/registrar service lookup
	useEffect(() => {
		if (cachedTransformedDomainNameInput.current !== debouncedTransformedDomainNameInput) {
			cachedTransformedDomainNameInput.current = debouncedTransformedDomainNameInput

			// abort any in-progress data update
			if (updateDataAbortController.current !== null && !updateDataAbortController.current.signal.aborted) {
				updateDataAbortController.current.abort(new Error('Domain name changed'))
				setLoadingProducts(false)
				setLoadingPrimaryContact(false)
			}

			// validate domain input
			if (!isProbablyFullyQualifiedDomainName(cachedTransformedDomainNameInput.current)) {
				setDomainNameInputError('Dette ser ikke ut som et gyldig domenenavn, prøv igjen')
				setLoadingRegistrarService(false)
				setRegistrarService(null)
				return
			}

			// create new abort controller
			updateDataAbortController.current = new AbortController()
			const abortController = updateDataAbortController.current

			setDomainNameInputError(null)
			setLoadingRegistrarService(true)
			setRegistrarService(null)
			getDomainRegistrarServiceByName(cachedTransformedDomainNameInput.current, { signal: abortController.signal })
				.then(registrarService => {
					handleRedirect(registrarService, routerNavigateFn)
					if (isObject(registrarService) && isObject(registrarService.data)) {
						setRegistrarService(registrarService.data)
					} else {
						setRegistrarService(null)
					}

				})
				.catch(e => {
					if (!abortController.signal.aborted) {
						// Add failure message (unknown error)
						console.error('Error looking up domain ' + cachedTransformedDomainNameInput.current + ':', e)
						setDomainNameInputError(e.message)
					}
				})
				.finally(() => {
					if (!abortController.signal.aborted) {
						setLoadingRegistrarService(false)
					}
				})
		}
	}, [debouncedTransformedDomainNameInput, routerNavigateFn])

	useEffect(() => {
		if (registrarService === null) {
			setDomainProduct(null)
			setTransferProduct(null)
			return
		}

		if (updateDataAbortController.current === null) {
			updateDataAbortController.current = new AbortController()
		}
		const abortController = updateDataAbortController.current

		setLoadingProducts(true)

		getProducts([
			{op: 'in', field: 'productCategory', value: [
				PRODUCT_CATEGORY_DOMAIN_NAME,
				PRODUCT_CATEGORY_DOMAIN_TRANSFER,
			]},
			{op: 'in', field: 'periodType', value: [
				PRODUCT_PERIOD_TYPE_ONE_TIME,
				PRODUCT_PERIOD_TYPE_YEARLY,
			]},
			{op: 'eq', field: 'isActive', value: true},
			{op: 'in', field: 'name', value: [
				'Domene .' + registrarService.domain_name_parts.tld,
				'Domene .' + registrarService.domain_name_parts.public_suffix,
				'Flytteavgift .' + registrarService.domain_name_parts.tld,
				'Flytteavgift .' + registrarService.domain_name_parts.public_suffix,
			]},
		], { limit: 4 }, { signal: abortController.signal })
			.then(res => {
				let domainProduct = null
				let transferProduct = null
				let domainProductNameMaxLength = 0
				let transferProductNameMaxLength = 0
				for (const product of res.data) {
					if (typeof product.name !== 'string') continue
					if (product.name.startsWith('Domene .') && product.name.length > domainProductNameMaxLength) {
						domainProduct = product
						domainProductNameMaxLength = product.name.length
					} else if (product.name.startsWith('Flytteavgift .') && product.name.length > transferProductNameMaxLength) {
						transferProduct = product
						transferProductNameMaxLength = product.name.length
					}
				}

				setDomainProduct(domainProduct)
				if (domainProduct !== null) {
					setDomainUnitPrice(formatNumber(domainProduct.unitPrice, { precision: 2 }))
				} else {
					setDomainUnitPrice('0')
				}
				setTransferProduct(transferProduct)
				if (transferProduct !== null) {
					setTransferUnitPrice(formatNumber(transferProduct.unitPrice, { precision: 2 }))
				} else {
					setTransferUnitPrice('0')
				}
			})
			.catch(e => {
				if (!abortController.signal.aborted) {
					console.error('Unable to load products:', e)
				}
			})
			.finally(() => {
				if (!abortController.signal.aborted) {
					setLoadingProducts(false)
				}
			})
	}, [registrarService])

	useEffect(() => {
		if (selectedCompany === null) {
			return
		} else if (companyCountry !== null && companyCountry.id === selectedCompany.countryID) {
			return
		}

		if (updateDataAbortController.current === null) {
			updateDataAbortController.current = new AbortController()
		}
		const abortController = updateDataAbortController.current

		setLoadingCompanyCountry(true)

		getCountry(selectedCompany.countryID, { signal: abortController.signal })
			.then(res => {
				handleRedirect(res, routerNavigateFn)
				if (isObject(res.data)) {
					setCompanyCountry(res.data)
				} else {
					setCompanyCountry(null)
				}
			})
			.catch(e => {
				handleRedirect(e.res, routerNavigateFn)
				console.error('Error getting country:', e)
			})
			.finally(() => {
				setLoadingCompanyCountry(false)
			})
	}, [selectedCompany, companyCountry, routerNavigateFn])

	useEffect(() => {
		if (selectedCompany === null) {
			return
		} else if (primaryContact !== null && primaryContact.companyID === selectedCompany.id) {
			return
		}

		if (updateDataAbortController.current === null) {
			updateDataAbortController.current = new AbortController()
		}
		const abortController = updateDataAbortController.current

		setLoadingPrimaryContact(true)

		getContacts([
			{ field: 'primaryContact', op: 'eq', value: true },
			{ field: 'companyID', op: 'eq', value: selectedCompany.id },
			{ field: 'isActive', op: 'eq', value: true },
		], { limit: 1 }, { signal: abortController.signal })
			.then(res => {
				handleRedirect(res, routerNavigateFn)
				if (!res.data || res.data.length === 0) return
				setPrimaryContact(res.data[0])
			})
			.catch(e => {
				handleRedirect(e.res, routerNavigateFn)
				console.error('Error getting primary contact:', e)
			})
			.finally(() => {
				setLoadingPrimaryContact(false)
			})
	}, [selectedCompany, primaryContact, routerNavigateFn])

	useEffect(() => {
		if (ownerContact === defaultContactData && isObject(selectedCompany) && !loadingCompanyCountry && !loadingPrimaryContact) {
			setOwnerContact(autotaskDomainContact)
			setApplicantDeclarationName(autotaskDomainContact.name ?? '')
		}
	}, [ownerContact, selectedCompany, loadingCompanyCountry, loadingPrimaryContact, autotaskDomainContact])

	const handleCreateDomain = () => {
		if (creatingDomain || !isObject(selectedCompany) || !isObject(selectedCompany.userDefinedFields) || !selectedCompany.userDefinedFields.hasOwnProperty('KitCloud UUID') || !isObject(domainNameParts) || ((!isTransfer || domainNameParts.tld !== 'no') && !domainContactIsValid(ownerContact, domainNameParts))) return

		setCreatingDomain(true)

		createDomain({
			domain: domainNameInput,
			authinfo_pw: isTransfer && transferCodeType === transferCodeTypeAuthInfo ? transferCode : null,
			norid_token: isTransfer && transferCodeType === transferCodeTypeNoridToken ? transferCode : null,
			account: {
				id: selectedCompany.userDefinedFields['KitCloud UUID'],
			},
			services: {
				dns: managedDNS,
			},
			bind_config: managedDNS ? bindConfig : null,
			nameservers: managedDNS ? null : customNameservers.map(hostname => ({ hostname })),
			contacts: {
				owner: isTransfer && domainNameParts.tld === 'no' ? null : ownerContact,
			},
			applicant_dataset_accept_name: domainNameParts.tld === 'no' ? applicantDeclarationName : null,
			applicant_dataset_accept_date: domainNameParts.tld === 'no' ? applicantDeclarationDate : null,
			applicant_dataset_accept_version_number: domainNameParts.tld === 'no' ? applicantDeclarationVersion : null,
			subscription_yearly_price: parseFloat(domainUnitPrice.replace(',', '.')),
			transfer_price: parseFloat(transferUnitPrice.replace(',', '.')),
		})
			.then(res => {
				handleRedirect(res, routerNavigateFn)
				if (isObject(res) && isObject(res.data) && res.data.hasOwnProperty('execution_id')) {
					setTaskExecutionId(res.data.execution_id)
				} else {
					setMessages(messages => messages.concat({
						key: 'warning_' + Math.round(new Date() / 1000).toString(),
						dismissable: true,
						type: 'warning',
						icon: 'warning sign',
						content: 'Mottok ikke bekreftelse på at oppretting/flytting av domenet ble satt i gang. Sjekk bakgrunnsjobber.',
					}))
				}
			})
			.catch(e => {
				handleRedirect(e.res, routerNavigateFn)
				setMessages(messages => messages.concat({
					key: 'error_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'error',
					icon: 'exclamation circle',
					content: 'Domenet kunne ikke opprettes/flyttes: ' + (e && e.message ? e.message : 'Ukjent feil (sjekk JavaScript-konsollen)'),
				}))
			})
			.finally(() => {
				setCreatingDomain(false)
			})
	}

	const renderDomainNameInputError = () => {
		if (!domainNameInputError) return null

		return <Label pointing="above" prompt>
			<Icon name="exclamation triangle" />&ensp;{domainNameInputError}
		</Label>
	}

	if (taskExecutionId !== null) {
		return <Segment vertical padded="very">
			<Container fluid>
				<Header size="huge">Startet oppretting av domene {domainNameInput}.</Header>

				<TaskExecutionLink
					header="Domene opprettes"
					id={taskExecutionId}
					onDismiss={() => {
						// reset
						setLoadingRegistrarService(false)
						setRegistrarService(null)

						setSelectedCompany(null)
						setLoadingCompanyCountry(null)
						setCompanyCountry(null)
						setLoadingPrimaryContact(null)
						setPrimaryContact(null)

						setTransferCodeType(transferCodeTypeAuthInfo)
						setTransferCode('')

						setOwnerContact(defaultContactData)

						setManagedDNS(true)
						setCustomNameservers([])

						setBindConfig(bindConfigParking)

						setApplicantDeclarationDate(new Date())
						setApplicantDeclarationVersion('3.2')
						setApplicantDeclarationName('')

						setLoadingProducts(false)
						setDomainProduct(null)
						setTransferProduct(null)
						setDomainUnitPrice(null)
						setTransferUnitPrice(null)

						setCreatingDomain(false)
						setTaskExecutionId(null)

						setDomainNameInputError(null)
						setDomainNameInput(initialDomainNameInput)
						setDebouncedTransformedDomainNameInput(initialDomainNameInput)
					}}
				/>
			</Container>
		</Segment>
	}

	return <Form className="kit-dash-segment-container kit-dash-scroll-container" onSubmit={preventDefault(() => handleCreateDomain())}>
		<div className="kit-dash-segment-container kit-dash-scroll-content">
			<Segment vertical padded="very" secondary>
				<Container fluid>
					<Header size="huge">
						Opprett eller flytt domene
						<Header.Subheader>Fyll ut skjemaet for å sette i gang oppretting eller flytting av domene.</Header.Subheader>
					</Header>
				</Container>
			</Segment>

			<Segment vertical padded="very">
				<Container fluid>
					<Header size="large">Domene</Header>

					<Form.Field error={!!domainNameInputError}>
						<Input
							fluid
							icon="globe"
							disabled={creatingDomain}
							loading={creatingDomain}
							placeholder="example.com"
							value={domainNameInput}
							onChange={(e, data) => setDomainNameInput(data.value)}
						/>
						{renderDomainNameInputError()}
					</Form.Field>

					<Form.Field>
						<label>Domeneoppslag</label>
						<DomainRegistrarServiceCard
							value={registrarService}
							loading={loadingRegistrarService}
						/>
					</Form.Field>

					{isTransfer ? <Form.Field disabled={creatingDomain}>
						<label>Type flyttekode</label>
						<Button.Group fluid basic>
							<Button
								disabled={creatingDomain}
								content="AuthInfo-kode"
								active={transferCodeType === transferCodeTypeAuthInfo}
								onClick={preventDefault(() => setTransferCodeType(transferCodeTypeAuthInfo))}
							/>
							<Button
								disabled={creatingDomain}
								content="Norid engangskode"
								active={transferCodeType === transferCodeTypeNoridToken}
								onClick={preventDefault(() => setTransferCodeType(transferCodeTypeNoridToken))}
							/>
						</Button.Group>
					</Form.Field> : null}

					{isTransfer ? <Form.Input
						label="Flyttekode"
						value={transferCode}
						onChange={(_, data) => setTransferCode(data.value)}
					/> : null}
				</Container>
			</Segment>

			<Segment vertical padded="very">
				<Container fluid>
					<Header size="large">
						Kunde
						<Header.Subheader>
							{loadingCompanyCountry || loadingPrimaryContact ? 'Laster inn ekstra info om kunde...' : (isObject(selectedCompany) ? 'Valgt kunde: ' + selectedCompany.companyName : 'Velg en kunde')}
						</Header.Subheader>
					</Header>

					<DataTable
						format="autotask"
						disabled={creatingDomain}
						searchOnly={accountId === null}
						limit={5}
						columns={[
							{ key: 'companyNumber', text: 'Kundenummer', visible: true, searchable: accountId === null },
							{ key: 'companyName', text: 'Kundenavn', visible: true, searchable: accountId === null },
							{ key: 'taxID', text: 'Organisasjonsnummer', visible: true, searchable: accountId === null },
						]}
						query={accountId === null ? initialCompanyQuery : [{field: 'KitCloud UUID', op: 'eq', value: accountId, udf: true}]}
						apiSearch={async (query, options, requestOptions) => {
							const res = await getCompanies(query, options, requestOptions)
							handleRedirect(res, routerNavigateFn)

							if (
								accountId !== null &&
								Array.isArray(res.data) &&
								res.data.length > 0 &&
								isObject(res.data[0]) &&
								isObject(res.data[0].userDefinedFields) &&
								res.data[0].userDefinedFields.hasOwnProperty('KitCloud UUID') &&
								res.data[0].userDefinedFields['KitCloud UUID'] === accountId
							) {
								setSelectedCompany(res.data[0])

								// pre-set some variables to avoid flash before effect
								if (primaryContact === null || primaryContact.companyID !== res.data[0].id) {
									setPrimaryContact(null)
									setLoadingPrimaryContact(true)
								}
								if (companyCountry === null || companyCountry.id !== res.data[0].countryID) {
									setCompanyCountry(null)
									setLoadingCompanyCountry(true)
								}
							}

							return res
						}}
						onClickRow={accountId === null ? (data => {
							setSelectedCompany(data)

							// pre-set some variables to avoid flash before effect
							if (primaryContact === null || primaryContact.companyID !== data.id) {
								setPrimaryContact(null)
								setLoadingPrimaryContact(true)
							}
							if (companyCountry === null || companyCountry.id !== data.countryID) {
								setCompanyCountry(null)
								setLoadingCompanyCountry(true)
							}
						}) : null}
						isRowActive={data => selectedCompany !== null && data.id === selectedCompany.id}
					/>
				</Container>
			</Segment>

			<Segment vertical padded="very">
				<Container fluid>
					<Header size="large">
						Juridisk eier
						<Header.Subheader>
							{!isObject(domainNameParts) ? 'Skriv inn et domenenavn før du fyller ut informasjon om juridisk eier' : (!isObject(selectedCompany) ? 'Velg en kunde før du fyller ut informasjon om juridisk eier' : (loadingCompanyCountry || loadingPrimaryContact ? 'Laster inn ekstra info om kunde...' : 'Fyll ut informasjon om juridisk eier av domenet'))}
						</Header.Subheader>
					</Header>

					{isTransfer && isObject(domainNameParts) && domainNameParts.tld === 'no' ? <p>Ved flytting av .no-domener forblir juridisk eier den samme. Du kan utføre et eierskifte etter at domenet er flyttet.</p> : <>
						<Form.Field>
							<label>Kontaktobjekt</label>
							<DomainContactSelect
								disabled={creatingDomain || !isObject(domainNameParts) || !isObject(selectedCompany) || loadingCompanyCountry || loadingPrimaryContact}
								registry={registry}
								publicSuffix={isObject(domainNameParts) ? domainNameParts.public_suffix : null}
								accountId={isObject(selectedCompany) && isObject(selectedCompany.userDefinedFields) && selectedCompany.userDefinedFields.hasOwnProperty('KitCloud UUID') ? selectedCompany.userDefinedFields['KitCloud UUID'] : null}
								value={ownerContact}
								onChange={newOwnerContact => setOwnerContact(newOwnerContact)}
								options={autotaskDomainContact !== null ? [autotaskDomainContact] : []}
								provideDefaultOption
							/>
						</Form.Field>

						<DomainContact
							fluid
							editMode
							type="owner"
							disabled={creatingDomain || !isObject(domainNameParts) || !isObject(selectedCompany) || loadingCompanyCountry || loadingPrimaryContact}
							domainNameParts={domainNameParts}
							data={ownerContact}
							dirty={ownerContact.id === '_custom' ? Object.keys(defaultContactData).map(key => {
								return JSON.stringify(defaultContactData[key]) === JSON.stringify(ownerContact[key]) ? null : key
							}).filter(key => key !== null) : []}
							onChange={newOwnerContact => setOwnerContact(append(newOwnerContact, {
								id: '_custom',
								account_id: null,
								handles: null,
								created_at: null,
								updated_at: null,
							}))}
						/>
					</>}
				</Container>
			</Segment>

			{isObject(domainNameParts) && domainNameParts.tld === 'no' ? <Segment vertical padded="very">
				<Container fluid>
					<Header size="large">
						Egenerklæring
						<Header.Subheader>Fyll ut navn og tid for signering av egenerklæring</Header.Subheader>
					</Header>

					<p>
						<Link href={applicantDeclarationDocsUrl} target="_blank" rel="noopener nofollow noreferrer">
							Kravspesifikasjon for egenerklæringen (Norid)
						</Link>
					</p>

					<Form.Group>
						<Form.Input
							width={9}
							label="Navn"
							value={applicantDeclarationName}
							onChange={(_, data) => setApplicantDeclarationName(data.value)}
						/>

						<SemanticDatepicker
							type="basic"
							locale="nb-NO"
							format={dateLocales.no.dateFormat}
							label="Dato"
							value={applicantDeclarationDate}
							onChange={(e, data) => setApplicantDeclarationDate(data.value)}
						/>

						<Form.Input
							width={3}
							label="Versjon"
							pattern="\d+\.\d+"
							value={applicantDeclarationVersion}
							onChange={(_, data) => setApplicantDeclarationVersion(data.value)}
						/>
					</Form.Group>
				</Container>
			</Segment> : null}

			<Segment vertical padded="very">
				<Container fluid>
					<Header size="large">DNS</Header>

					<Form.Checkbox
						toggle
						disabled={creatingDomain}
						checked={managedDNS}
						onChange={(_, data) => {
							setManagedDNS(data.checked)
							setCustomNameservers([])
						}}
						label="Bruk Konsept-IT sin DNS-tjeneste"
					/>

					<Form.Field disabled={managedDNS}>
						<label>DNS-servere</label>
					</Form.Field>
					{managedDNS ? defaultNameservers.map((hostname, i) => <Form.Input
						key={i}
						value={hostname}
						readOnly
						disabled
					/>) : <NameserversInput
						value={customNameservers}
						min={isObject(domainNameParts) ? domainNameParts.min_ns : 1}
						max={isObject(domainNameParts) ? domainNameParts.max_ns : 13}
						disabled={creatingDomain}
						onChange={newNameservers => setCustomNameservers(newNameservers)}
					/>}

					<Form.TextArea
						label="DNS-pekere / sonefil (BIND format)"
						rows={15}
						disabled={!managedDNS || creatingDomain}
						value={bindConfig}
						onChange={(_, data) => setBindConfig(data.value)}
					/>
				</Container>
			</Segment>

			<Segment vertical padded="very">
				<Container fluid>
					<Header size="large">Fakturering</Header>

					<Form.Field>
						<label>Kunde</label>
						{isObject(selectedCompany) ? selectedCompany.companyName : 'Ingen kunde valgt'}
					</Form.Field>

					{isTransfer ? <>
						<Header size="medium">Flytteavgift</Header>

						<Form.Field>
							<label>Produkt</label>
							{loadingRegistrarService || loadingProducts ? <Placeholder><Placeholder.Line /></Placeholder> : (isObject(transferProduct) ? transferProduct.name : 'Skriv inn et domenenavn...')}
						</Form.Field>

						<Form.Field>
							<label>Kostnad</label>
							{loadingRegistrarService || loadingProducts ? <Placeholder><Placeholder.Line /></Placeholder> : (isObject(transferProduct) ? formatNumber(transferProduct.unitCost, { precision: 2 }) + ' kr' : 'Skriv inn et domenenavn...')}
						</Form.Field>

						<Form.Input
							type="number"
							step="1"
							label="Pris (kr)"
							value={transferUnitPrice}
							onChange={(_, data) => setTransferUnitPrice(data.value)}
						/>
					</> : null}

					<Header size="medium">Årsavgift</Header>

					<Form.Field>
						<label>Produkt</label>
						{loadingRegistrarService || loadingProducts ? <Placeholder><Placeholder.Line /></Placeholder> : (isObject(domainProduct) ? domainProduct.name : 'Skriv inn et domenenavn...')}
					</Form.Field>

					<Form.Field>
						<label>Kostnad</label>
						{loadingRegistrarService || loadingProducts ? <Placeholder><Placeholder.Line /></Placeholder> : (isObject(domainProduct) ? formatNumber(domainProduct.unitCost, { precision: 2 }) + ' kr/år' : 'Skriv inn et domenenavn...')}
					</Form.Field>

					<Form.Input
						type="number"
						step="1"
						label="Pris (kr/år)"
						value={domainUnitPrice}
						onChange={(_, data) => setDomainUnitPrice(data.value)}
					/>
				</Container>
			</Segment>
		</div>

		<Segment vertical padded secondary>
			<Container fluid>
				<Button type="submit" color="orange" loading={creatingDomain} disabled={creatingDomain || !isObject(selectedCompany) || !isObject(domainNameParts) || ((!isTransfer || domainNameParts.tld !== 'no') && !domainContactIsValid(ownerContact, domainNameParts))}>
					<Icon name="plus" /> Opprett domene
				</Button>
			</Container>
		</Segment>
	</Form>
}