import React, { useMemo, useState } from 'react'
import { Segment, Container, Header, Icon, Label, Message, Checkbox, Form, Grid } from 'semantic-ui-react'
import { append, preventDefault, tobool, updateQueryParams } from '../../helpers'
import AssetLabel from '../../components/asset_label'
import { useLoaderData } from 'react-router-dom'
import Dash from '../../components/dash'

const defaultQueryParams = {
	name_label: 'PC-navn',
	name: 'PC-NAVN',
	meta1_label: 'Serienummer [S/N]',
	meta1: '1234',
	meta1_barcode: '1',
	meta2_label: 'Modellnummer [P/N]',
	meta2: '1234',
	meta2_barcode: '1',
}

const fallback = (value, fallback) => typeof value !== 'string' ? fallback : value
const fallbackEmpty = (value, fallback) => typeof value !== 'string' || value.length === 0 ? fallback : value

// /tools/generate-label?name=PC-NAVN&meta1=SN&meta2=MN
export async function loader({ request }) {
	const { searchParams } = new URL(request.url)

	return {
		nameLabel: fallback(searchParams.get('name_label'), defaultQueryParams.name_label),
		name: fallback(searchParams.get('name'), defaultQueryParams.name),
		meta1Label: fallback(searchParams.get('meta1_label'), defaultQueryParams.meta1_label),
		meta1: fallback(searchParams.get('meta1'), defaultQueryParams.meta1),
		meta1Barcode: tobool(fallback(searchParams.get('meta1_barcode'), defaultQueryParams.meta1_barcode)),
		meta2Label: fallback(searchParams.get('meta2_label'), defaultQueryParams.meta2_label),
		meta2: fallback(searchParams.get('meta2'), defaultQueryParams.meta2),
		meta2Barcode: tobool(fallback(searchParams.get('meta2_barcode'), defaultQueryParams.meta2_barcode)),
	}
}

export default function GenerateLabelToolPage() {
	const loaderData = useLoaderData()
	const [asset, setAsset] = useState(loaderData)

	const updateData = change => {
		console.log(change)
		const newData = append(asset, change)
		setAsset(newData)
		updateQueryParams({
			name_label: newData.nameLabel,
			name: newData.name,
			meta1_label: newData.meta1Label,
			meta1: newData.meta1,
			meta1_barcode: newData.meta1Barcode ? '1' : '0',
			meta2_label: newData.meta2Label,
			meta2: newData.meta2,
			meta2_barcode: newData.meta2Barcode ? '1' : '0',
		})
	}

	const {
		nameLabel,
		name,
		meta1Label,
		meta1,
		meta1Barcode,
		meta2Label,
		meta2,
		meta2Barcode,
	} = asset

	const canShowBarcode = name.length < 12

	return <>
		<Dash constrained>
			<style dangerouslySetInnerHTML={{__html: '@page { size: 89mm 36mm; margin: 0; } @media print { .kit-dash { display: none; } }'}} />
			<Segment vertical padded="very">
				<Container>
					<Header size="huge">Lag merkelapp</Header>
					<p>På denne siden kan du lage merkelapper til PCer. Bare trykk på feltene du vil redigere og skriv inn ny verdi.</p>
					<p>For å skrive ut merkelappen, trykk <kbd>Ctrl</kbd> + <kbd>P</kbd> eller trykk på knappen under.</p>
					<Message
						info
						icon="print"
						header="Velg riktig printer"
						content={
							<p>
								Husk å velge riktig printer når du skal printe ut lappen. Riktig printer hos Konsept-IT heter <b><code>KIT-PRINT3 (Dymo LabelWriter 400 Turbo)</code></b>
							</p>
						}
					/>

					<Grid stackable columns={2}>
						<Grid.Column width={11}>
							<Segment inverted color="orange" compact>
								<p>
									<Label as="a" ribbon="right" onClick={preventDefault(() => window.print())}><Icon name="print" className="left" /> Skriv ut</Label>
									<b style={{display: 'inline-block', marginLeft: '-7em'}}>Forhåndsvisning <code>(89 mm x 36 mm)</code></b>
								</p>
								<AssetLabel
									nameLabel={nameLabel}
									name={name}
									meta1={meta1}
									meta2={meta2}
									meta1Label={meta1Label}
									meta2Label={meta2Label}
									meta1Barcode={canShowBarcode && meta1Barcode}
									meta2Barcode={canShowBarcode && meta2Barcode}
									onChange={change => updateData(change)}
								/>
							</Segment>
						</Grid.Column>

						<Grid.Column width={5}>
							<Form onSubmit={preventDefault()}>
								<Form.Checkbox
									toggle
									label={'Vis strekkode for ' + fallbackEmpty(meta1Label, 'felt 1')}
									disabled={!canShowBarcode}
									checked={meta1Barcode}
									onChange={(_, data) => updateData({meta1Barcode: data.checked})}
								/>
								<Form.Checkbox
									toggle
									label={'Vis strekkode for ' + fallbackEmpty(meta2Label, 'felt 2')}
									disabled={!canShowBarcode}
									checked={meta2Barcode}
									onChange={(_, data) => updateData({meta2Barcode: data.checked})}
								/>
							</Form>
						</Grid.Column>
					</Grid>
				</Container>
			</Segment>
		</Dash>
		<AssetLabel
			className="kit-print-only"
			nameLabel={nameLabel}
			name={name}
			meta1={meta1}
			meta2={meta2}
			meta1Label={meta1Label}
			meta2Label={meta2Label}
			meta1Barcode={canShowBarcode && meta1Barcode}
			meta2Barcode={canShowBarcode && meta2Barcode}
		/>
	</>
}