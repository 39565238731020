import React from 'react'
import {
	PERIOD_TYPE_MONTHLY as CONTRACT_PERIOD_TYPE_MONTHLY,
	PERIOD_TYPE_QUARTERLY as CONTRACT_PERIOD_TYPE_QUARTERLY,
	PERIOD_TYPE_SEMIANNUAL as CONTRACT_PERIOD_TYPE_SEMIANNUAL,
	PERIOD_TYPE_YEARLY as CONTRACT_PERIOD_TYPE_YEARLY,
} from './api/autotask/contracts'
import {
	PERIOD_TYPE_MONTHLY as SERVICE_PERIOD_TYPE_MONTHLY,
	PERIOD_TYPE_QUARTERLY as SERVICE_PERIOD_TYPE_QUARTERLY,
	PERIOD_TYPE_SEMIANNUAL as SERVICE_PERIOD_TYPE_SEMIANNUAL,
	PERIOD_TYPE_YEARLY as SERVICE_PERIOD_TYPE_YEARLY,
} from './api/autotask/services'
import {
	PERIOD_TYPE_ONE_TIME as SUBSCRIPTION_PERIOD_TYPE_ONE_TIME,
	PERIOD_TYPE_MONTHLY as SUBSCRIPTION_PERIOD_TYPE_MONTHLY,
	PERIOD_TYPE_QUARTERLY as SUBSCRIPTION_PERIOD_TYPE_QUARTERLY,
	PERIOD_TYPE_SEMI_ANNUAL as SUBSCRIPTION_PERIOD_TYPE_SEMI_ANNUAL,
	PERIOD_TYPE_YEARLY as SUBSCRIPTION_PERIOD_TYPE_YEARLY,
} from './api/autotask/subscriptions'
import {
	PERIOD_TYPE_ONE_TIME as PRODUCT_PERIOD_TYPE_ONE_TIME,
	PERIOD_TYPE_MONTHLY as PRODUCT_PERIOD_TYPE_MONTHLY,
	PERIOD_TYPE_QUARTERLY as PRODUCT_PERIOD_TYPE_QUARTERLY,
	PERIOD_TYPE_SEMIANNUAL as PRODUCT_PERIOD_TYPE_SEMIANNUAL,
	PERIOD_TYPE_YEARLY as PRODUCT_PERIOD_TYPE_YEARLY,
} from './api/autotask/products'
import Link from './components/link'
import { redirect } from 'react-router-dom'

/* eslint no-control-regex: off */
export const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i
export const emailAliasRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")?@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i
export const emailLocalPartRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")$/i
export const emailDomainRegex = /^(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i

export const phoneNumberRegex = /^(?:0011|0(?:0[019]?|1[01]|20)?|119|810|\+|\uFF0B)?[.\d\s()]+$/i

const hostRegexPart = /(?:(?!-)[-a-z0-9\u00a1-\uffff]*[a-z0-9\u00a1-\uffff]+(?!.\/|\.$)\.?){2,}/i
export const hostRegex = new RegExp("^" + hostRegexPart.source + "$", "i")

export const urlRegex = new RegExp(
	"^" +
		// protocol identifier + //
		"https?://" +
		// user:pass authentication (optional)
		"(?:\\S+(?::\\S*)?@)?" +
		// host (optional) + domain + tld
		hostRegexPart.source +
		// server port number (optional)
		"(?::\\d{2,5})?" +
		// resource path (optional)
		"(?:/\\S*)?" +
	"$", "i"
);

export const countryOptions = {
	no: [
		{value: "NO", text: "Norge"},
		{value: "SE", text: "Sverige"},
		{value: "DK", text: "Danmark"},
		{value: "FI", text: "Finland"},
		{value: "AF", text: "Afghanistan"},
		{value: "AL", text: "Albania"},
		{value: "DZ", text: "Algerie"},
		{value: "AS", text: "Amerikansk Samoa"},
		{value: "AD", text: "Andorra"},
		{value: "AO", text: "Angola"},
		{value: "AI", text: "Anguilla"},
		{value: "AQ", text: "Antarktis"},
		{value: "AG", text: "Antigua og Barbuda"},
		{value: "AR", text: "Argentina"},
		{value: "AM", text: "Armenia"},
		{value: "AW", text: "Aruba"},
		{value: "AZ", text: "Aserbajdsjan"},
		{value: "AU", text: "Australia"},
		{value: "BS", text: "Bahamas"},
		{value: "BH", text: "Bahrain"},
		{value: "BD", text: "Bangladesh"},
		{value: "BB", text: "Barbados"},
		{value: "BY", text: "Belarus (Hviterussland)"},
		{value: "BE", text: "Belgia"},
		{value: "BZ", text: "Belize"},
		{value: "BJ", text: "Benin"},
		{value: "BM", text: "Bermuda"},
		{value: "BT", text: "Bhutan"},
		{value: "BO", text: "Bolivia"},
		{value: "BA", text: "Bosnia-Hercegovina"},
		{value: "BW", text: "Botswana"},
		{value: "BV", text: "Bouvetøya"},
		{value: "BR", text: "Brasil"},
		{value: "BN", text: "Brunei"},
		{value: "BG", text: "Bulgaria"},
		{value: "BF", text: "Burkina Faso"},
		{value: "BI", text: "Burundi"},
		{value: "CA", text: "Canada"},
		{value: "KY", text: "Caymanøyene"},
		{value: "CL", text: "Chile"},
		{value: "CO", text: "Colombia"},
		{value: "CK", text: "Cookøyene"},
		{value: "CR", text: "Costa Rica"},
		{value: "CU", text: "Cuba"},
		{value: "VI", text: "De amerikanske Jomfruøyer"},
		{value: "VG", text: "De britiske Jomfruøyer"},
		{value: "AE", text: "De forente arabiske emirater"},
		{value: "TF", text: "De franske sørterritorier"},
		{value: "AN", text: "De nederlandske Antiller"},
		{value: "DO", text: "Den dominikanske republikk"},
		{value: "CF", text: "Den sentralafrikanske republikk"},
		{value: "IO", text: "Det britiske territoriet i Indiahavet"},
		{value: "DJ", text: "Djibouti"},
		{value: "DM", text: "Dominica"},
		{value: "EC", text: "Ecuador"},
		{value: "EG", text: "Egypt"},
		{value: "GQ", text: "Ekvatorial-Guinea"},
		{value: "SV", text: "El Salvador"},
		{value: "CI", text: "Elfenbenskysten"},
		{value: "ER", text: "Eritrea"},
		{value: "EE", text: "Estland"},
		{value: "SZ", text: "Eswatini"},
		{value: "ET", text: "Etiopia"},
		{value: "FK", text: "Falklandsøyene"},
		{value: "FJ", text: "Fiji"},
		{value: "PH", text: "Filippinene"},
		{value: "FR", text: "Frankrike"},
		{value: "GF", text: "Guyana"},
		{value: "PF", text: "Polynesia"},
		{value: "FO", text: "Færøyene"},
		{value: "GA", text: "Gabon"},
		{value: "GM", text: "Gambia"},
		{value: "GE", text: "Georgia"},
		{value: "GH", text: "Ghana"},
		{value: "GI", text: "Gibraltar"},
		{value: "GL", text: "Grønland"},
		{value: "GD", text: "Grenada"},
		{value: "GP", text: "Guadeloupe"},
		{value: "GU", text: "Guam"},
		{value: "GT", text: "Guatemala"},
		{value: "GN", text: "Guinea"},
		{value: "GW", text: "Guinea-Bissau"},
		{value: "GY", text: "Guyana"},
		{value: "HT", text: "Haiti"},
		{value: "GR", text: "Hellas"},
		{value: "HM", text: "Heardøya og McDonaldøyene"},
		{value: "HN", text: "Honduras"},
		{value: "HK", text: "Hongkong"},
		{value: "IN", text: "India"},
		{value: "ID", text: "Indonesia"},
		{value: "IQ", text: "Irak"},
		{value: "IR", text: "Iran"},
		{value: "IE", text: "Irland"},
		{value: "IS", text: "Island"},
		{value: "IL", text: "Israel"},
		{value: "IT", text: "Italia"},
		{value: "JM", text: "Jamaica"},
		{value: "JP", text: "Japan"},
		{value: "YE", text: "Jemen"},
		{value: "JE", text: "Jersey"},
		{value: "JO", text: "Jordan"},
		{value: "KH", text: "Kambodsja"},
		{value: "CM", text: "Kamerun"},
		{value: "CV", text: "Kapp Verde"},
		{value: "KZ", text: "Kasakhstan"},
		{value: "KE", text: "Kenya"},
		{value: "CN", text: "Kina"},
		{value: "KG", text: "Kirgisistan"},
		{value: "KI", text: "Kiribati"},
		{value: "CX", text: "Kiritimati (Juleøya)"},
		{value: "CC", text: "Kokosøyene"},
		{value: "KM", text: "Komorene"},
		{value: "CD", text: "Kongo, Den demokratiske republikken"},
		{value: "CG", text: "Kongo, Republikken"},
		{value: "XK", text: "Kosovo"},
		{value: "HR", text: "Kroatia"},
		{value: "KW", text: "Kuwait"},
		{value: "CY", text: "Kypros"},
		{value: "LA", text: "Laos"},
		{value: "LV", text: "Latvia"},
		{value: "LS", text: "Lesotho"},
		{value: "LB", text: "Libanon"},
		{value: "LR", text: "Liberia"},
		{value: "LY", text: "Libya"},
		{value: "LI", text: "Liechtenstein"},
		{value: "LT", text: "Litauen"},
		{value: "LU", text: "Luxembourg"},
		{value: "MO", text: "Macao"},
		{value: "MG", text: "Madagaskar"},
		{value: "MW", text: "Malawi"},
		{value: "MY", text: "Malaysia"},
		{value: "MV", text: "Maldivene"},
		{value: "ML", text: "Mali"},
		{value: "MT", text: "Malta"},
		{value: "IM", text: "Man"},
		{value: "MA", text: "Marokko"},
		{value: "MH", text: "Marshalløyene"},
		{value: "MQ", text: "Martinique"},
		{value: "MR", text: "Mauritania"},
		{value: "MU", text: "Mauritius"},
		{value: "YT", text: "Mayotte"},
		{value: "MX", text: "Mexico"},
		{value: "FM", text: "Mikronesia"},
		{value: "MD", text: "Moldova"},
		{value: "MC", text: "Monaco"},
		{value: "MN", text: "Mongolia"},
		{value: "ME", text: "Montenegro"},
		{value: "MS", text: "Montserrat"},
		{value: "MZ", text: "Mosambik"},
		{value: "MM", text: "Myanmar (Burma)"},
		{value: "NA", text: "Namibia"},
		{value: "NR", text: "Nauru"},
		{value: "NL", text: "Nederland"},
		{value: "NP", text: "Nepal"},
		{value: "NZ", text: "New Zealand"},
		{value: "NI", text: "Nicaragua"},
		{value: "NE", text: "Niger"},
		{value: "NG", text: "Nigeria"},
		{value: "NU", text: "Niue"},
		{value: "NF", text: "Norfolkøya"},
		{value: "KP", text: "Nord-Korea"},
		{value: "MK", text: "Nord-Makedonia"},
		{value: "MP", text: "Nord-Marianene"},
		{value: "NC", text: "Ny-Caledonia"},
		{value: "OM", text: "Oman"},
		{value: "PK", text: "Pakistan"},
		{value: "PW", text: "Palau"},
		{value: "PS", text: "Palestina"},
		{value: "PA", text: "Panama"},
		{value: "PG", text: "Papua Ny-Guinea"},
		{value: "PY", text: "Paraguay"},
		{value: "PE", text: "Peru"},
		{value: "PN", text: "Pitcairn"},
		{value: "PL", text: "Polen"},
		{value: "PT", text: "Portugal"},
		{value: "PR", text: "Puerto Rico"},
		{value: "QA", text: "Qatar"},
		{value: "RE", text: "Réunion"},
		{value: "RO", text: "Romania"},
		{value: "RU", text: "Russland"},
		{value: "RW", text: "Rwanda"},
		{value: "KN", text: "Saint Kitts og Nevis"},
		{value: "LC", text: "Saint Lucia"},
		{value: "VC", text: "Saint Vincent og Grenadinene"},
		{value: "BL", text: "Saint-Barthélemy"},
		{value: "PM", text: "Saint-Pierre-et-Miquelon"},
		{value: "SH", text: "Sankt Helena"},
		{value: "SB", text: "Salomonøyene"},
		{value: "WS", text: "Samoa"},
		{value: "SM", text: "San Marino"},
		{value: "SA", text: "Saudi-Arabia"},
		{value: "SN", text: "Senegal"},
		{value: "RS", text: "Serbia"},
		{value: "SC", text: "Seychellene"},
		{value: "SL", text: "Sierra Leone"},
		{value: "SG", text: "Singapore"},
		{value: "MF", text: "Sint Maarten"},
		{value: "SK", text: "Slovakia"},
		{value: "SI", text: "Slovenia"},
		{value: "SO", text: "Somalia"},
		{value: "ES", text: "Spania"},
		{value: "LK", text: "Sri Lanka"},
		{value: "GB", text: "Storbritannia"},
		{value: "SD", text: "Sudan"},
		{value: "SR", text: "Surinam"},
		{value: "CH", text: "Sveits"},
		{value: "SJ", text: "Svalbard og Jan Mayen"},
		{value: "SY", text: "Syria"},
		{value: "ST", text: "São Tomé og Príncipe"},
		{value: "ZA", text: "Sør-Afrika"},
		{value: "GS", text: "Sør-Georgia og Sør-Sandwichøyene"},
		{value: "KR", text: "Sør-Korea"},
		{value: "SS", text: "Sør-Sudan"},
		{value: "TJ", text: "Tadsjikistan"},
		{value: "TW", text: "Taiwan"},
		{value: "TZ", text: "Tanzania"},
		{value: "TH", text: "Thailand"},
		{value: "TG", text: "Togo"},
		{value: "TK", text: "Tokelau"},
		{value: "TO", text: "Tonga"},
		{value: "TT", text: "Trinidad og Tobago"},
		{value: "TD", text: "Tsjad"},
		{value: "CZ", text: "Tsjekkia"},
		{value: "TN", text: "Tunisia"},
		{value: "TM", text: "Turkmenistan"},
		{value: "TC", text: "Turks- og Caicosøyene"},
		{value: "TV", text: "Tuvalu"},
		{value: "TR", text: "Tyrkia"},
		{value: "DE", text: "Tyskland"},
		{value: "UG", text: "Uganda"},
		{value: "UA", text: "Ukraina"},
		{value: "HU", text: "Ungarn"},
		{value: "UY", text: "Uruguay"},
		{value: "US", text: "USA"},
		{value: "UM", text: "USAs ytre småøyer"},
		{value: "UZ", text: "Usbekistan"},
		{value: "VU", text: "Vanuatu"},
		{value: "VA", text: "Vatikanstaten"},
		{value: "VE", text: "Venezuela"},
		{value: "EH", text: "Vest-Sahara"},
		{value: "VN", text: "Vietnam"},
		{value: "WF", text: "Wallis og Futuna"},
		{value: "ZM", text: "Zambia"},
		{value: "ZW", text: "Zimbabwe"},
		{value: "TL", text: "Øst-Timor"},
		{value: "AT", text: "Østerrike"},
		{value: "AX", text: "Åland"},
	],
	en: [
		{value: "NO", text: "Norway"},
		{value: "SE", text: "Sweden"},
		{value: "DK", text: "Denmark"},
		{value: "FI", text: "Finland"},
		{value: "AF", text: "Afghanistan"},
		{value: "AX", text: "Aland Islands"},
		{value: "AL", text: "Albania"},
		{value: "DZ", text: "Algeria"},
		{value: "AS", text: "American Samoa"},
		{value: "AD", text: "Andorra"},
		{value: "AO", text: "Angola"},
		{value: "AI", text: "Anguilla"},
		{value: "AQ", text: "Antarctica"},
		{value: "AG", text: "Antigua and Barbuda"},
		{value: "AR", text: "Argentina"},
		{value: "AM", text: "Armenia"},
		{value: "AW", text: "Aruba"},
		{value: "AU", text: "Australia"},
		{value: "AT", text: "Austria"},
		{value: "AZ", text: "Azerbaijan"},
		{value: "BS", text: "Bahamas"},
		{value: "BH", text: "Bahrain"},
		{value: "BD", text: "Bangladesh"},
		{value: "BB", text: "Barbados"},
		{value: "BY", text: "Belarus"},
		{value: "BE", text: "Belgium"},
		{value: "BZ", text: "Belize"},
		{value: "BJ", text: "Benin"},
		{value: "BM", text: "Bermuda"},
		{value: "BT", text: "Bhutan"},
		{value: "BO", text: "Bolivia"},
		{value: "BA", text: "Bosnia and Herzegovina"},
		{value: "BW", text: "Botswana"},
		{value: "BV", text: "Bouvet Island"},
		{value: "BR", text: "Brazil"},
		{value: "IO", text: "British Indian Ocean Territory"},
		{value: "BN", text: "Brunei"},
		{value: "BG", text: "Bulgaria"},
		{value: "BF", text: "Burkina Faso"},
		{value: "BI", text: "Burundi"},
		{value: "KH", text: "Cambodia"},
		{value: "CM", text: "Cameroon"},
		{value: "CA", text: "Canada"},
		{value: "CV", text: "Cape Verde"},
		{value: "KY", text: "Cayman Islands"},
		{value: "CF", text: "Central African Republic"},
		{value: "TD", text: "Chad"},
		{value: "CL", text: "Chile"},
		{value: "CN", text: "China"},
		{value: "CX", text: "Christmas Island"},
		{value: "CC", text: "Cocos (Keeling) Islands"},
		{value: "CO", text: "Colombia"},
		{value: "KM", text: "Comoros"},
		{value: "CG", text: "Congo"},
		{value: "CK", text: "Cook Islands"},
		{value: "CR", text: "Costa Rica"},
		{value: "CI", text: "Cote d'ivoire"},
		{value: "HR", text: "Croatia"},
		{value: "CU", text: "Cuba"},
		{value: "CY", text: "Cyprus"},
		{value: "CZ", text: "Czech Republic"},
		{value: "CD", text: "Democratic Republic of the Congo"},
		{value: "DJ", text: "Djibouti"},
		{value: "DM", text: "Dominica"},
		{value: "DO", text: "Dominican Republic"},
		{value: "EC", text: "Ecuador"},
		{value: "EG", text: "Egypt"},
		{value: "SV", text: "El Salvador"},
		{value: "GQ", text: "Equatorial Guinea"},
		{value: "ER", text: "Eritrea"},
		{value: "EE", text: "Estonia"},
		{value: "ET", text: "Ethiopia"},
		{value: "FK", text: "Falkland Islands"},
		{value: "FO", text: "Faroe Islands"},
		{value: "FJ", text: "Fiji"},
		{value: "FR", text: "France"},
		{value: "GF", text: "French Guiana"},
		{value: "PF", text: "French Polynesia"},
		{value: "TF", text: "French Southern Territories"},
		{value: "GA", text: "Gabon"},
		{value: "GM", text: "Gambia"},
		{value: "GE", text: "Georgia"},
		{value: "DE", text: "Germany"},
		{value: "GH", text: "Ghana"},
		{value: "GI", text: "Gibraltar"},
		{value: "GR", text: "Greece"},
		{value: "GL", text: "Greenland"},
		{value: "GD", text: "Grenada"},
		{value: "GP", text: "Guadaloupe"},
		{value: "GU", text: "Guam"},
		{value: "GT", text: "Guatemala"},
		{value: "GW", text: "Guinea-Bissau"},
		{value: "GN", text: "Guinea"},
		{value: "GY", text: "Guyana"},
		{value: "HT", text: "Haiti"},
		{value: "HM", text: "Heard Island and McDonald Islands"},
		{value: "HN", text: "Honduras"},
		{value: "HK", text: "Hong Kong"},
		{value: "HU", text: "Hungary"},
		{value: "IS", text: "Iceland"},
		{value: "IN", text: "India"},
		{value: "ID", text: "Indonesia"},
		{value: "IR", text: "Iran"},
		{value: "IQ", text: "Iraq"},
		{value: "IE", text: "Ireland"},
		{value: "IM", text: "Isle of Man"},
		{value: "IL", text: "Israel"},
		{value: "IT", text: "Italy"},
		{value: "JM", text: "Jamaica"},
		{value: "JP", text: "Japan"},
		{value: "JE", text: "Jersey"},
		{value: "JO", text: "Jordan"},
		{value: "KZ", text: "Kazakhstan"},
		{value: "KE", text: "Kenya"},
		{value: "KI", text: "Kiribati"},
		{value: "XK", text: "Kosovo"},
		{value: "KW", text: "Kuwait"},
		{value: "KG", text: "Kyrgyzstan"},
		{value: "LA", text: "Laos"},
		{value: "LV", text: "Latvia"},
		{value: "LB", text: "Lebanon"},
		{value: "LS", text: "Lesotho"},
		{value: "LR", text: "Liberia"},
		{value: "LY", text: "Libya"},
		{value: "LI", text: "Liechtenstein"},
		{value: "LT", text: "Lithuania"},
		{value: "LU", text: "Luxembourg"},
		{value: "MO", text: "Macao"},
		{value: "MG", text: "Madagascar"},
		{value: "MW", text: "Malawi"},
		{value: "MY", text: "Malaysia"},
		{value: "MV", text: "Maldives"},
		{value: "ML", text: "Mali"},
		{value: "MT", text: "Malta"},
		{value: "MH", text: "Marshall Islands"},
		{value: "MQ", text: "Martinique"},
		{value: "MR", text: "Mauritania"},
		{value: "MU", text: "Mauritius"},
		{value: "YT", text: "Mayotte"},
		{value: "MX", text: "Mexico"},
		{value: "FM", text: "Micronesia"},
		{value: "MD", text: "Moldava"},
		{value: "MC", text: "Monaco"},
		{value: "MN", text: "Mongolia"},
		{value: "ME", text: "Montenegro"},
		{value: "MS", text: "Montserrat"},
		{value: "MA", text: "Morocco"},
		{value: "MZ", text: "Mozambique"},
		{value: "MM", text: "Myanmar"},
		{value: "NA", text: "Namibia"},
		{value: "NR", text: "Nauru"},
		{value: "NP", text: "Nepal"},
		{value: "AN", text: "Netherlands Antilles"},
		{value: "NL", text: "Netherlands"},
		{value: "NC", text: "New Caledonia"},
		{value: "NZ", text: "New Zealand"},
		{value: "NI", text: "Nicaragua"},
		{value: "NE", text: "Niger"},
		{value: "NG", text: "Nigeria"},
		{value: "NU", text: "Niue"},
		{value: "NF", text: "Norfolk Island"},
		{value: "KP", text: "North Korea"},
		{value: "MK", text: "North Macedonia"},
		{value: "MP", text: "Northern Mariana Islands"},
		{value: "OM", text: "Oman"},
		{value: "PK", text: "Pakistan"},
		{value: "PW", text: "Palau"},
		{value: "PS", text: "Palestinian Territory"},
		{value: "PA", text: "Panama"},
		{value: "PG", text: "Papua New Guinea"},
		{value: "PY", text: "Paraguay"},
		{value: "PE", text: "Peru"},
		{value: "PH", text: "Phillipines"},
		{value: "PN", text: "Pitcairn"},
		{value: "PL", text: "Poland"},
		{value: "PT", text: "Portugal"},
		{value: "PR", text: "Puerto Rico"},
		{value: "QA", text: "Qatar"},
		{value: "RE", text: "Reunion"},
		{value: "RO", text: "Romania"},
		{value: "RU", text: "Russia"},
		{value: "RW", text: "Rwanda"},
		{value: "BL", text: "Saint Barthelemy"},
		{value: "SH", text: "Saint Helena"},
		{value: "KN", text: "Saint Kitts and Nevis"},
		{value: "LC", text: "Saint Lucia"},
		{value: "MF", text: "Saint Martin"},
		{value: "PM", text: "Saint Pierre and Miquelon"},
		{value: "VC", text: "Saint Vincent and the Grenadines"},
		{value: "WS", text: "Samoa"},
		{value: "SM", text: "San Marino"},
		{value: "ST", text: "Sao Tome and Principe"},
		{value: "SA", text: "Saudi Arabia"},
		{value: "SN", text: "Senegal"},
		{value: "RS", text: "Serbia"},
		{value: "SC", text: "Seychelles"},
		{value: "SL", text: "Sierra Leone"},
		{value: "SG", text: "Singapore"},
		{value: "SK", text: "Slovakia"},
		{value: "SI", text: "Slovenia"},
		{value: "SB", text: "Solomon Islands"},
		{value: "SO", text: "Somalia"},
		{value: "ZA", text: "South Africa"},
		{value: "GS", text: "South Georgia and the South Sandwich Islands"},
		{value: "KR", text: "South Korea"},
		{value: "ES", text: "Spain"},
		{value: "LK", text: "Sri Lanka"},
		{value: "SS", text: "South Sudan"},
		{value: "SD", text: "Sudan"},
		{value: "SR", text: "Suriname"},
		{value: "SJ", text: "Svalbard and Jan Mayen"},
		{value: "SZ", text: "Swaziland"},
		{value: "CH", text: "Switzerland"},
		{value: "SY", text: "Syria"},
		{value: "TW", text: "Taiwan"},
		{value: "TJ", text: "Tajikistan"},
		{value: "TZ", text: "Tanzania"},
		{value: "TH", text: "Thailand"},
		{value: "TL", text: "Timor-Leste"},
		{value: "TG", text: "Togo"},
		{value: "TK", text: "Tokelau"},
		{value: "TO", text: "Tonga"},
		{value: "TT", text: "Trinidad and Tobago"},
		{value: "TN", text: "Tunisia"},
		{value: "TR", text: "Turkey"},
		{value: "TM", text: "Turkmenistan"},
		{value: "TC", text: "Turks and Caicos Islands"},
		{value: "TV", text: "Tuvalu"},
		{value: "UG", text: "Uganda"},
		{value: "UA", text: "Ukraine"},
		{value: "AE", text: "United Arab Emirates"},
		{value: "GB", text: "United Kingdom"},
		{value: "UM", text: "United States Minor Outlying Islands"},
		{value: "US", text: "United States"},
		{value: "UY", text: "Uruguay"},
		{value: "UZ", text: "Uzbekistan"},
		{value: "VU", text: "Vanuatu"},
		{value: "VA", text: "Vatican City"},
		{value: "VE", text: "Venezuela"},
		{value: "VN", text: "Vietnam"},
		{value: "VG", text: "Virgin Islands, British"},
		{value: "VI", text: "Virgin Islands, US"},
		{value: "WF", text: "Wallis and Futuna"},
		{value: "EH", text: "Western Sahara"},
		{value: "YE", text: "Yemen"},
		{value: "ZM", text: "Zambia"},
		{value: "ZW", text: "Zimbabwe"},
	],
}

let _globalState = {
	csrf: null,
}

export function setGlobalState(state = {}) {
	_globalState = append(_globalState, state)
}

function getGlobalState(key) {
	if (typeof key === 'undefined' || key === undefined) {
		return _globalState
	} else if (_globalState.hasOwnProperty(key)) {
		return _globalState[key]
	}
	return null
}

export function parseQuery(input) {
	if (input.length === 0) return {}
	var qsPos = input.indexOf('?')
	if (qsPos !== -1) input = input.substring(qsPos + 1)
	return input.split('&').map(kvstr => {
		return kvstr.split('=', 2).map(item => decodeURIComponent(item))
	}).reduce((o, cur) => {
		o[cur[0]] = cur.length > 1 ? cur[1] : null
		return o
	}, {})
}

export function buildQueryValue(value) {
	if (typeof value === 'undefined' || value === undefined) return ''
	if (typeof value === 'object' && value === null) return ''
	if (typeof value === 'boolean') return value ? '1' : '0'
	return encodeURIComponent(value)
}

export function buildQuery(input, prefix = '?') {
	if (!isObject(input) || input.length === 0) return ''
	return prefix + Object.keys(input).map(key => {
		return encodeURIComponent(key) + '=' + buildQueryValue(input[key])
	}).join('&')
}

export function ltrim(str, charset = ' \t\r\n') {
	while (str.length > 0 && charset.includes(str.charAt(0))) {
		str = str.substring(1);
	}
	return str
}

export function rtrim(str, charset = ' \t\r\n') {
	while (str.length > 0 && charset.includes(str.charAt(str.length - 1))) {
		str = str.substring(0, str.length - 1);
	}
	return str
}

export function trim(str, charset = ' \t\r\n') {
	return ltrim(rtrim(str, charset), charset)
}

export function stripLast(str, strip) {
	return str.length >= strip.length && str.endsWith(strip) ? str.substring(0, str.length - strip.length) : str
}

export function keepAfter(str, substr) {
	let substrPos = str.indexOf(substr)
	if (substrPos < 0) return str
	return str.substring(substrPos + substr.length)
}

export function keepBefore(str, substr) {
	let substrPos = str.indexOf(substr)
	if (substrPos < 0) return str
	return str.substring(0, substrPos)
}

export function stripUrlFragment(str) {
	return keepBefore(str, '#')
}

export function stripUrlQueryString(str) {
	return keepBefore(str, '?')
}

export function stripUrlPath(str) {
	return keepBefore(str, '/')
}

export function stripUrlScheme(str) {
	return keepAfter(str, '://')
}

export function extractUrlHostPath(str) {
	return stripUrlQueryString(stripUrlFragment(stripUrlScheme(str)))
}

export function relativeUrlEquals(link1, link2, origin = '') {
	origin = stripUrlPath(extractUrlHostPath(origin))
	link1 = extractUrlHostPath(link1)
	link2 = extractUrlHostPath(link2)

	const link1_path = keepAfter(rtrim(link1, '/') + '/', '/')
	const link2_path = keepAfter(rtrim(link2, '/') + '/', '/')
	const link1_origin = stripUrlPath(link1)
	const link2_origin = stripUrlPath(link2)

	return link1_path === link2_path && (
		origin.length === 0 ||
		link1_origin.length === 0 ||
		link1_origin === origin
	) && (
		origin.length === 0 ||
		link2_origin.length === 0 ||
		link2_origin === origin
	)
}

export function urlOriginEquals(link, origin) {
	const link_origin = stripUrlPath(extractUrlHostPath(link))
	return link_origin === stripUrlPath(extractUrlHostPath(origin)) ||
		link_origin.length === 0
}

export function ucfirst(str) {
	return str.substring(0, 1).toUpperCase() + str.substring(1)
}

export const dateLocales = {
	no: {
		dateFormat: 'DD.MM.YYYY',
		shortMonths: ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'],
		months: ['januar', 'februar', 'mars', 'april', 'mai', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'desember'],
		formats: {
			unknown: 'ukjent',
			now: 'akkurat nå',
			today: 'i dag',
			todayTime: 'i dag kl {{hour}}:{{minute}}',
			todayTimeWithSeconds: 'i dag kl {{hour}}:{{minute}}:{{second}}',
			todayHours: 'i dag kl {{hour}}',
			thisMonth: 'denne måneden',
			thisYear: 'i år',
			past: 'for %s siden',
			future: 'om %s',
			date: "{{day_number}}. {{month_name}}",
			dateWithYear: "{{day_number}}. {{month_name}} {{year}}",
			shortDate: "{{day_number}}. {{month_name_short}}",
			shortDateWithYear: "{{day_number}}. {{month_name_short}} {{year}}",
			time: "kl {{hour}}:{{minute}}",
			hours: "kl {{hour}}",
			dateTime: "{{day_number}}. {{month_name}}, {{hour}}:{{minute}}",
			dateTimeWithSeconds: "{{day_number}}. {{month_name}}, {{hour}}:{{minute}}:{{second}}",
			dateTimeWithYear: "{{day_number}}. {{month_name}} {{year}}, {{hour}}:{{minute}}",
			dateTimeWithSecondsAndYear: "{{day_number}}. {{month_name}} {{year}}, {{hour}}:{{minute}}:{{second}}",
			shortDateTime: "{{day_number}}. {{month_name_short}}, {{hour}}:{{minute}}",
			shortDateTimeWithSeconds: "{{day_number}}. {{month_name_short}}, {{hour}}:{{minute}}:{{second}}",
			shortDateTimeWithYear: "{{day_number}}. {{month_name_short}} {{year}}, {{hour}}:{{minute}}",
			shortDateTimeWithSecondsAndYear: "{{day_number}}. {{month_name_short}} {{year}}, {{hour}}:{{minute}}:{{second}}",
			timespans: {
				year: '%d år',
				years: '%d år',
				month: '%d måned',
				months: '%d måneder',
				week: '%d uke',
				weeks: '%d uker',
				day: '%d dag',
				days: '%d dager',
				hour: '%d time',
				hours: '%d timer',
				minute: '%d minutt',
				minutes: '%d minutter',
				second: '%d sekund',
				seconds: '%d sekunder',
			},
		},
	},
	en: {
		dateFormat: 'YYYY-MM-DD',
		shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
		ordinals: {
			0: 'th',
			1: 'st',
			2: 'nd',
			3: 'rd',
			4: 'th',
			5: 'th',
			6: 'th',
			7: 'th',
			8: 'th',
			9: 'th',
		},
		formats: {
			unknown: 'unknown',
			now: 'just now',
			today: 'today',
			todayTime: 'today at {{hour_ampm}}:{{minute}} {{ampm}}',
			todayTimeWithSeconds: 'today at {{hour_ampm}}:{{minute}}:{{second}} {{ampm}}',
			todayHours: 'today at {{hour_ampm}} {{ampm}}',
			thisMonth: 'this month',
			thisYear: 'this year',
			past: '%s ago',
			future: 'in %s',
			date: "{{month_name}} {{day_number}}{{day_ordinal}}",
			dateWithYear: "{{month_name}} {{day_number}}{{day_ordinal}}, {{year}}",
			shortDate: "{{month_name_short}} {{day_number}}{{day_ordinal}}",
			shortDateWithYear: "{{month_name_short}} {{day_number}}{{day_ordinal}}, {{year}}",
			time: "at {{hour_ampm}}:{{minute}} {{ampm}}",
			hours: "at {{hour_ampm}} {{ampm}}",
			dateTime: "{{month_name}} {{day_number}}{{day_ordinal}} at {{hour_ampm}}:{{minute}} {{ampm}}",
			dateTimeWithSeconds: "{{month_name}} {{day_number}}{{day_ordinal}} at {{hour_ampm}}:{{minute}}:{{second}} {{ampm}}",
			dateTimeWithYear: "{{month_name}} {{day_number}}{{day_ordinal}}, {{year}} at {{hour_ampm}}:{{minute}} {{ampm}}",
			dateTimeWithSecondsAndYear: "{{month_name}} {{day_number}}{{day_ordinal}}, {{year}} at {{hour_ampm}}:{{minute}}:{{second}} {{ampm}}",
			shortDateTime: "{{month_name_short}} {{day_number}}{{day_ordinal}} at {{hour_ampm}}:{{minute}} {{ampm}}",
			shortDateTimeWithSeconds: "{{month_name_short}} {{day_number}}{{day_ordinal}} at {{hour_ampm}}:{{minute}}:{{second}} {{ampm}}",
			shortDateTimeWithYear: "{{month_name_short}} {{day_number}}{{day_ordinal}}, {{year}} at {{hour_ampm}}:{{minute}} {{ampm}}",
			shortDateTimeWithSecondsAndYear: "{{month_name_short}} {{day_number}}{{day_ordinal}}, {{year}} at {{hour_ampm}}:{{minute}}:{{second}} {{ampm}}",
			timespans: {
				year: '%d year',
				years: '%d years',
				month: '%d month',
				months: '%d months',
				week: '%d week',
				weeks: '%d weeks',
				day: '%d day',
				days: '%d days',
				hour: '%d hour',
				hours: '%d hours',
				minute: '%d minute',
				minutes: '%d minutes',
				second: '%d second',
				seconds: '%d seconds',
			},
		},
	},
}

export function dateToSQL(input) {
	if (!(input instanceof Date)) {
		input = new Date(input)
	}
	if (!input) return null
	return formatDate("{{year}}-{{month_number_pad}}-{{day_number_pad}} {{hour}}:{{minute}}:{{second}}")
}

export function dateToTimestamp(input) {
	if (!(input instanceof Date)) {
		input = new Date(input)
	}
	if (!input) return null
	return Math.floor(input.getTime() / 1000)
}

export function formatDate(format, input, locale = dateLocales.no, localTime = true) {
	const { months, shortMonths } = locale
	const ordinals = dateLocales.en.ordinals
	return format.split(/(\{\{.*?\}\})/g)
		.map(part => {
			switch (part) {
			case "{{day_number}}":
				return input['get' + (localTime ? '' : 'UTC') + 'Date']().toString()
			case "{{day_number_pad}}":
				return input['get' + (localTime ? '' : 'UTC') + 'Date']().toString().padStart(2, '0')
			case "{{day_ordinal}}":
				return ordinals[parseInt(input['get' + (localTime ? '' : 'UTC') + 'Date']().toString().slice(-1))]
			case "{{month_number}}":
				return (input['get' + (localTime ? '' : 'UTC') + 'Month']() + 1).toString()
			case "{{month_number_pad}}":
				return (input['get' + (localTime ? '' : 'UTC') + 'Month']() + 1).toString().padStart(2, '0')
			case "{{month_name}}":
				return months[input['get' + (localTime ? '' : 'UTC') + 'Month']()]
			case "{{month_name_short}}":
				return shortMonths[input['get' + (localTime ? '' : 'UTC') + 'Month']()]
			case "{{year}}":
				return input['get' + (localTime ? '' : 'UTC') + 'FullYear']().toString().padStart(4, '0')
			case "{{hour}}":
				return input['get' + (localTime ? '' : 'UTC') + 'Hours']().toString().padStart(2, '0')
			case "{{hour_ampm}}":
				const hours = input['get' + (localTime ? '' : 'UTC') + 'Hours']()
				if (hours === 0) return '12'
				if (hours > 12) {
					return (hours - 12).toString()
				}
				return hours.toString()
			case "{{ampm}}":
				if (input['get' + (localTime ? '' : 'UTC') + 'Hours']() < 12) {
					return 'am'
				} else {
					return 'pm'
				}
			case "{{minute}}":
				return input['get' + (localTime ? '' : 'UTC') + 'Minutes']().toString().padStart(2, '0')
			case "{{second}}":
				return input['get' + (localTime ? '' : 'UTC') + 'Seconds']().toString().padStart(2, '0')
			default:
				return part
			}
		})
		.join("")
}

export function dateToString(input, includeTime = true, includeSeconds = false, short = true, relativeToCurrentDate = true, locale = dateLocales.no) {
	const { formats } = locale
	if (!(input instanceof Date)) {
		input = new Date(input)
	}
	const now = new Date()
	if (!input || !now) return formats.unknown

	if (relativeToCurrentDate && now.getUTCFullYear() === input.getUTCFullYear()) {
		if (now.getUTCMonth() === input.getUTCMonth() && now.getUTCDate() === input.getUTCDate()) {
			if (includeTime) {
				if (includeSeconds) {
					return formatDate(formats.todayTimeWithSeconds, input, locale)
				} else {
					return formatDate(formats.todayTime, input, locale)
				}
			} else {
				return formatDate(formats.today, input, locale)
			}
		} else {
			if (includeTime) {
				if (includeSeconds) {
					return formatDate(short ? formats.shortDateTimeWithSeconds : formats.dateTimeWithSeconds, input, locale)
				} else {
					return formatDate(short ? formats.shortDateTime : formats.dateTime, input, locale)
				}
			} else {
				return formatDate(short ? formats.shortDate : formats.date, input, locale)
			}
		}
	} else {
		if (includeTime) {
			if (includeSeconds) {
				return formatDate(short ? formats.shortDateTimeWithSecondsAndYear : formats.dateTimeWithSecondsAndYear, input, locale)
			} else {
				return formatDate(short ? formats.shortDateTimeWithYear : formats.dateTimeWithYear, input, locale)
			}
		} else {
			return formatDate(short ? formats.shortDateWithYear : formats.dateWithYear, input, locale)
		}
	}
}

export function dateToRelativeString(input, precision = 5, locale = dateLocales.no) {
	const { formats } = locale
	if (!(input instanceof Date)) {
		input = new Date(input)
	}
	const now = new Date()
	if (!input || !now) return formats.unknown

	const d1 = input > now ? now : input
	const d2 = input > now ? input : now
	const diff = d2 - d1
	if (diff < 1000) return formats.now

	const relativeFormat = input > now ? formats.future : formats.past;
	const precisionSteps = [
		{
			diff: diff / (1000 * 60 * 60 * 24 * 365),
			fallback: formats.thisYear,
			timespan: {
				single: formats.timespans.year,
				multiple: formats.timespans.years,
			},
		},
		{
			diff: diff / (1000 * 60 * 60 * 24 * 31),
			fallback: formats.thisMonth,
			timespan: {
				single: formats.timespans.month,
				multiple: formats.timespans.months,
			},
		},
		{
			diff: diff / (1000 * 60 * 60 * 24),
			fallback: formats.today,
			timespan: {
				single: formats.timespans.day,
				multiple: formats.timespans.days,
			},
		},
		{
			diff: diff / (1000 * 60 * 60),
			fallback: formatDate(formats.todayHours, input, locale),
			timespan: {
				single: formats.timespans.hour,
				multiple: formats.timespans.hours,
			},
		},
		{
			diff: diff / (1000 * 60),
			fallback: formatDate(formats.todayTime, input, locale),
			timespan: {
				single: formats.timespans.minute,
				multiple: formats.timespans.minutes,
			},
		},
		{
			diff: diff / 1000,
			fallback: formatDate(formats.todayTimeWithSeconds, input, locale),
			timespan: {
				single: formats.timespans.second,
				multiple: formats.timespans.seconds,
			},
		},
	]

	let str = ''
	let i = 0
	for (let step of precisionSteps) {
		if (precision < i) break
		if (step.diff > 1) {
			if (i === 0) {
				str = formatDate(formats.shortDateWithYear, input, locale)
			} else if (i === 1) {
				str = formatDate(formats.shortDate, input, locale)
			} else {
				const flooredDiff = Math.floor(step.diff)
				if (flooredDiff === 1) {
					str = step.timespan.single.replace('%d', flooredDiff)
				} else if (flooredDiff > 1) {
					str = step.timespan.multiple.replace('%d', flooredDiff)
				}
				str = relativeFormat.replace('%s', str)
			}
			break
		} else if (step.diff < 1 && str.length === 0) {
			str = step.fallback
		}
		i++
	}
	return str
}

export function age(then, now = null) {
	if (!(then instanceof Date)) {
		then = new Date(then)
	}
	if (!(now instanceof Date)) {
		now = now ? new Date(now) : new Date()
	}
	if (!now || !then) return null

	const monthDiff = now.getMonth() - then.getMonth()
	const yearDiff = now.getFullYear() - then.getFullYear()

	let diff = yearDiff
	if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < then.getDate())) {
		diff -= 1
	}
	return diff
}

export function nowBeforeDate(date) {
	return isObject(date) && date instanceof Date && (new Date()) < date
}

export function nowAfterDate(date) {
	return isObject(date) && date instanceof Date && (new Date()) > date
}

export function base64UrlEncode(input) {
	return encodeURIComponent(btoa(input).replace(/\//g, '_').replace(/\+/g, '-'))
}

export function base64UrlDecode(input) {
	return atob(decodeURIComponent(input).replace(/_/g, '/').replace(/-/g, '+'))
}

export function serializePublicKeyCredential(cred, register = false) {
	let serialized = {
		id: cred.id,
		type: cred.type,
		response: {
			clientDataJSON: base64UrlEncode(String.fromCharCode(...new Uint8Array(cred.response.clientDataJSON)))
		}
	}
	if (register) serialized.transports = cred.response.getTransports()
	if (register && cred.response.attestationObject) serialized.response.attestationObject = base64UrlEncode(String.fromCharCode(...new Uint8Array(cred.response.attestationObject)))
	if (!register && cred.response.authenticatorData) serialized.response.authenticatorData = base64UrlEncode(String.fromCharCode(...new Uint8Array(cred.response.authenticatorData)))
	if (!register && cred.response.signature) serialized.response.signature = base64UrlEncode(String.fromCharCode(...new Uint8Array(cred.response.signature)))
	if (!register && cred.response.userHandle) serialized.response.userHandle = base64UrlEncode(String.fromCharCode(...new Uint8Array(cred.response.userHandle)))
	return serialized
}

const defaultFormatNumberOptions = {
	asString: true,
	precision: 20,
	truncate: false,
	decimalSeparator: ',',
	negativeSign: '- ',
	thounsandSeparator: ' ',
	stripDecimalTrailingZero: true,
}
export function formatNumber(number, options = defaultFormatNumberOptions) {
	options = append(defaultFormatNumberOptions, options)
	number = parseFloat(number.toString().replace(',', '.'))
	if (typeof options.precision !== 'number' || options.precision < 1) {
		number = options.truncate ? Math.trunc(number) : Math.round(number)
	} else {
		number = number.toFixed(options.precision)
	}
	let [num, dec] = number.toString().split('.', 2)
	let neg = num.charAt(0) === '-'
	if (neg) {
		num = num.substring(1)
	}
	if (options.asString && typeof options.thounsandSeparator === 'string' && options.thounsandSeparator.length > 0) {
		for (let i = num.length - 3; i > 0; i -= 3) {
			num = num.substring(0, i) + ' ' + num.substring(i)
		}
	}
	if (typeof dec === 'undefined' || dec === undefined) {
		dec = ''
	} else if (options.stripDecimalTrailingZero) {
		dec = dec.replace(/0*$/, '')
	}
	if (!options.asString) return parseFloat((neg ? '-' : '') + num + (dec.length > 0 ? '.' + dec : ''))
	return (neg ? options.negativeSign : '') + num + (dec.length > 0 ? options.decimalSeparator + dec : '')
}

export function formatNumberForInputField(number, options = {}) {
	options = append({ negativeSign: '-', thounsandSeparator: '', decimalSeparator: '.' }, options)
	return formatNumber(number, options)
}

const defaultFormatBytesOptions = {...defaultFormatNumberOptions, base: 2, precision: 2}
export function formatBytes(number, options = defaultFormatBytesOptions) {
	const suffixes = {
		2: ['byte', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'],
		10: ['byte', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
	};
	const baseSuffixes = options.base in suffixes ? suffixes[options.base] : suffixes[2];
	const mul = options.base === 2 ? 1024 : 1000
	let i = 0, suffix_min = 1, suffix_div = 1, suffix = baseSuffixes[i];
	for (; i < baseSuffixes.length; i++) {
		suffix = baseSuffixes[i];
		suffix_div = suffix_min;
		suffix_min = mul ** (i + 1);
		if (number < suffix_min) break;
	}
	number = number / suffix_div;
	if (suffix === 'byte') {
		const isSingleByte = parseFloat(number.toFixed(options.precision)) === 1.0;
		if (!isSingleByte) suffix = 'bytes';
	}
	return formatNumber(number, options) + ' ' + suffix;
}

export function idnToAscii(domain) {
	let a = document.createElement('a')
	a.href = window.location.protocol + '//' + domain
	return a.hostname
}

let abortController = new AbortController();

export async function request(endpoint, data = null, options = {}) {
	if (typeof options !== 'object' || Array.isArray(options)) {
		options = {}
	}
	if (!options.hasOwnProperty('method')) {
		options.method = data === null ? 'GET' : 'POST'
	}
	if (!options.hasOwnProperty('mode')) {
		options.mode = 'same-origin'
	}
	if (!options.hasOwnProperty('cache')) {
		options.cache = 'no-store'
	}
	let headers = {}
	const csrf = getGlobalState('csrf')
	if (typeof csrf === 'string' && csrf.length > 0) {
		headers['X-CSRF-Token'] = csrf
	}
	headers['Accept'] = 'application/json';
	if (options.hasOwnProperty('body') || data !== null) {
		headers['Content-Type'] = 'application/json; charset=utf-8'
	}
	if (!options.hasOwnProperty('headers')) {
		options.headers = headers
	} else {
		options.headers = Object.assign(headers, options.headers)
	}
	if (!options.hasOwnProperty('body') && data !== null) {
		if (options.headers['Content-Type'].split(';', 2)[0].trim().toLowerCase() === 'application/json') {
			options.body = JSON.stringify(data)
		} else {
			options.body = data
		}
	}
	if (!options.hasOwnProperty('signal')) {
		options.signal = abortController.signal;
	}

	let res = null
	try {
		res = await fetch(endpoint, options)
	} catch (e) {
		if (!e) throw new Error('request error: unknown error while fetching "' + endpoint + '"')
		if (e.message) console.error('request error: %s while fetching "%s"', e.message, endpoint)
		throw e
	}
	if (Math.floor(res.status / 100) === 3 && res.headers.has('Location')) {
		return {
			success: false,
			redirect: res.headers.get('Location'),
		}
	}
	if (res.headers.get('Content-Type').split(';', 2)[0].trim().toLowerCase() !== 'application/json') {
		return res
	}
	let json = null
	try {
		json = await res.json()
	} catch (e) {
		if (!e) throw new Error('request error: unknown JSON decode error while fetching "' + endpoint + '"')
		if (e.message) console.error('request error: JSON decode error: %s while fetching "%s"', e.message, endpoint)
		throw e
	}
	if (!json) throw new Error('request error: empty response body while fetching "' + endpoint + '"')
	if (!json.success) {
		// handle error
		let err
		if (json.hasOwnProperty('error') && json.error.hasOwnProperty('message')) {
			err = new Error(json.error.message)
		} else {
			err = new Error('request error: not successful while fetching "' + endpoint + '"')
		}
		err.res = json
		throw err
	}
	return json
}

export function handleRedirect(json, routerNavigateFn) {
	if (!isObject(json)) return null
	if (typeof json.redirect !== 'string' || json.redirect.length <= 0) return null

	// handle re-auth
	if (relativeUrlEquals(json.redirect, '/auth', window.location.origin) || urlOriginEquals(json.redirect, 'login.microsoftonline.com')) {
		console.info('re-auth')
		// TODO: attempt silent re-auth with iframe
		// re-try with interactive popup on error indicating user intervention is required
	}

	return navigate(json.redirect, '_self', {}, routerNavigateFn)
}

export function genUUID() {
	if (crypto.randomUUID) return crypto.randomUUID()
	let buf = crypto.getRandomValues(new Uint8Array(16))
	// As per RFC 4122 section 4.4, set bits for version and clock_seq_hi_and_reserved
	buf[6] = (buf[6] & 0x0f) | 0x40
	buf[8] = (buf[8] & 0x3f) | 0x80
	const hex = [...buf].map(c => c.toString(16).padStart(2, '0'))
	return [hex.slice(0, 4), hex.slice(4, 6), hex.slice(6, 8), hex.slice(8, 10), hex.slice(10, 16)].map(c => c.join('')).join('-')
}

export function genCorrelationId(prefix) {
	const len = 35 - prefix.length - 1;
	return prefix + '-' + [...crypto.getRandomValues(new Uint8Array(Math.ceil(len / 2)))].map(c => c.toString(16).padStart(2, '0')).join('').substring(0, len)
}

export function renderOrgId(orgId) {
	if (typeof orgId !== 'string') return null

	// strip whitespace
	let noOrgId = orgId.replace(/[\s\u0020\u00A0\u1680\u2000-\u200A\u202F\u205F\u3000\u2028\u2029]/ig, '')

	// check length, check and strip prefix/suffix
	if (
		noOrgId.length === 9 ||
		(
			noOrgId.substring(0, 2).toUpperCase() === 'NO' && (
				noOrgId.length === 11 || (
					noOrgId.length === 14 &&
					noOrgId.slice(-3).toUpperCase() === 'MVA'
				)
			)
		)
	) {
		noOrgId = noOrgId.replace(/[^\d]/g, '')
	}
	if (noOrgId.length === 9) {
		return <Link href={'https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=' + encodeURIComponent(noOrgId)} target="_blank" rel="noopener nofollow noreferrer">{orgId}</Link>
	}
	return orgId
}

export const filterTypes = {
	date: 'date',
	boolean: 'boolean',
	search: 'search',
	model: 'model',
	booleanQuery: 'booleanQuery',
}

function getSearchQueryPart(columns, search, format = 'internal') {
	if (format === 'odata') {
		const words = search.split(/[\s\u0020\u00A0\u1680\u2000-\u200A\u202F\u205F\u3000\u2028\u2029]/i).filter(part => part !== '')
		if (words.length === 0) return ''

		// build query from keywords
		let query = []
		for (const { key, searchable } of columns) {
			if (!searchable) continue
			let fieldQuery = []
			for (const word of words) {
				fieldQuery.push('contains(' + key.replace(/\./g, '/') + ', \'' + word.replace(/\\/g, '\\\\').replace(/'/g, '\\\'') + '\')')
			}
			fieldQuery = fieldQuery.filter(part => part !== '')
			if (fieldQuery.length === 1) {
				query.push(fieldQuery[0])
			} else if (fieldQuery.length > 1) {
				query.push('(' + fieldQuery.join(' and ') + ')')
			}
		}

		query = query.filter(part => part !== '')
		if (query.length === 0) return ''
		if (query.length === 1) return query[0]
		return '(' + query.join(' or ') + ')'
	}

	const fieldKey = format === 'autotask' ? 'field' : 'key'
	const valueKey = format === 'autotask' ? 'value' : 'val'
	const groupKey = format === 'autotask' ? 'items' : 'grp'

	const words = search.split(/[\s\u0020\u00A0\u1680\u2000-\u200A\u202F\u205F\u3000\u2028\u2029]/i).filter(part => part !== '')
	if (words.length === 0) return null

	// build query from keywords
	let query = {op: 'or', [groupKey]: []}
	for (const { key, searchable } of columns) {
		if (!searchable) continue
		let fieldQuery = {op: 'and', [groupKey]: []}
		for (const word of words) {
			if (format === 'autotask') {
				let wordGroup = {op: 'or', [groupKey]: []}
				for (const part of word.split('*')) {
					wordGroup[groupKey].push({[fieldKey]: key, op: 'contains', [valueKey]: part})
				}
				if (wordGroup[groupKey].length === 1) {
					wordGroup = wordGroup[groupKey][0]
				}
				fieldQuery[groupKey].push(wordGroup)
			} else {
				fieldQuery[groupKey].push({[fieldKey]: key, op: 'like', [valueKey]: '*' + trim(word, '*') + '*'})
			}
		}
		if (fieldQuery[groupKey].length === 1) {
			fieldQuery = fieldQuery[groupKey][0]
		}
		query[groupKey].push(fieldQuery)
	}
	if (query[groupKey].length === 1) {
		query = query[groupKey][0]
	} else if (query[groupKey].length === 0) {
		return null
	}

	return query
}

export function getSearchQuery(baseQuery, columns, search, filterValues = null, format = 'internal') {
	if (format === 'cloudflare' || format === 'tripletex') {
		let query = append(format === 'cloudflare' ? {match: 'any'}: {}, baseQuery)
		for (const key of Object.keys(filterValues)) {
			if (query.hasOwnProperty(key)) continue
			const value = filterValues[key]
			if (typeof value === 'undefined' || value === null) continue
			const column = columns.find(column => column.key === key)
			if (!column) continue
			if (column.filterType === filterTypes.search) {
				let searchColumns = [column.key]
				if (typeof column.filterSearchColumns === 'object' && Array.isArray(column.filterSearchColumns)) {
					searchColumns = column.filterSearchColumns
				}
				for (const searchColumn of searchColumns) {
					query[searchColumn] = value
				}
			} else if (column.filterType === filterTypes.boolean) {
				query[key] = value === '1'
			} else if (column.filterType === filterTypes.date && value.length === 1) {
				query[key] = value[0].toISOString()
			}
		}
		for (const { key, searchable } of columns) {
			if (!searchable || query.hasOwnProperty(key)) continue
			query[key] = search
		}
		return query
	} else if (format === 'odata') {
		let query = typeof baseQuery === 'object' && Array.isArray(baseQuery) ? [...baseQuery] : []
		query.push(getSearchQueryPart(columns, search, format))
		for (const key of Object.keys(filterValues)) {
			const value = filterValues[key]
			if (typeof value === 'undefined' || value === null) continue
			const column = columns.find(column => column.key === key)
			if (!column) continue
			if (column.filterType === filterTypes.search) {
				let searchColumns = [key]
				if (typeof column.filterSearchColumns === 'object' && Array.isArray(column.filterSearchColumns)) {
					searchColumns = column.filterSearchColumns
				}
				query.push(getSearchQueryPart(searchColumns.map(searchColumn => ({key: searchColumn, visible: false, searchable: true})), value, format))
			} else if (column.filterType === filterTypes.boolean) {
				query.push(key.replace(/\./g, '/') + ' eq ' + (value === '1' ? 'true' : 'false'))
			} else if (column.filterType === filterTypes.date && value.length === 1) {
				query.push(key.replace(/\./g, '/') + ' eq ' + value[0].toISOString())
			} else if (column.filterType === filterTypes.booleanQuery && column.filterQuery.hasOwnProperty(value) && column.filterQuery[value]) {
				query.push(column.filterQuery[value])
			}
		}
		query = query.filter(part => part !== '')
		if (query.length === 0) return ''
		if (query.length === 1) return query[0]
		return query.join(' and ')
	}

	const fieldKey = format === 'autotask' ? 'field' : 'key'
	const valueKey = format === 'autotask' ? 'value' : 'val'
	const groupKey = format === 'autotask' ? 'items' : 'grp'

	let parts = []
	const searchQuery = getSearchQueryPart(columns, search, format)
	if (typeof searchQuery === 'object' && searchQuery !== null) {
		if (Array.isArray(searchQuery) && searchQuery.length > 0) {
			parts = parts.concat(searchQuery)
		} else {
			parts.push(searchQuery)
		}
	}

	// add base query
	if (typeof baseQuery === 'object' && baseQuery !== null) {
		if (Array.isArray(baseQuery)) {
			parts = parts.concat(baseQuery)
		} else {
			parts.push(baseQuery)
		}
	}

	// add filter values
	if (typeof filterValues === 'object' && filterValues !== null) {
		const part = Object.keys(filterValues).map(k => {
			const value = filterValues[k]
			if (typeof value === 'undefined' || value === null) return null

			const column = columns.find(column => column.key === k)
			if (!column) return null

			if (column.filterType === filterTypes.search) {
				// Example: value = 'test'
				let searchColumns = [column.key]
				if (typeof column.filterSearchColumns === 'object' && Array.isArray(column.filterSearchColumns)) {
					searchColumns = column.filterSearchColumns
				}
				return getSearchQueryPart(searchColumns.map(searchColumn => ({key: searchColumn, visible: false, searchable: true})), value, format)
			} else if (column.filterType === filterTypes.boolean) {
				// Example: value = '1'
				// Example: value = '0'
				return {op: 'eq', [fieldKey]: column.key, [valueKey]: value === '1'}
			} else if (column.filterType === filterTypes.booleanQuery && column.filterQuery.hasOwnProperty(value) && column.filterQuery[value]) {
				return column.filterQuery[value]
			} else if (column.filterType === filterTypes.date) {
				// Example: value = []
				// Example: value = [new Date()]
				// Example: value = [new Date(), new Date()]
				if (value.length === 1) {
					return {op: 'eq', [fieldKey]: column.key, [valueKey]: value[0].toISOString()}
				} else if (value.length === 2) {
					return {op: 'and', [groupKey]: [
						{op: 'gte', [fieldKey]: column.key, [valueKey]: value[0].toISOString()},
						{op: 'lte', [fieldKey]: column.key, [valueKey]: value[1].toISOString()},
					]}
				}
			} else if (column.filterType === filterTypes.model) {
				// Example: value = {id: 'UUID', ...}
				return {op: 'eq', [fieldKey]: column.key, [valueKey]: value[column.filterKey]}
			}

			return null
		}).filter(item => item !== null)
		parts = parts.concat(part)
	}

	return format === 'autotask' ? parts : (parts.length === 0 ? null : (parts.length === 1 ? parts[0] : {op: 'and', [groupKey]: parts}))
}

export function isObject(item) {
	return typeof item === 'object' && !Array.isArray(item) && item !== null
}

export function clone(target) {
	if (typeof target === 'object') return Array.isArray(target) ? [...target] : {...target}
	return target
}

export function append(target, ...sources) {
	if (Array.isArray(target)) return target.concat(...sources)
	if (isObject(target)) return Object.assign({...target}, ...sources)
	return target
}

export function appendWhere(target, predicate, ...sources) {
	if (typeof predicate !== 'function') throw new TypeError('predicate must be a function')
	if (Array.isArray(target)) return target.map((item, i) => predicate(item, i) ? append(item, ...sources) : clone(item))
	if (isObject(target)) return Object.keys(target).reduce((obj, key) => {
		obj[key] = predicate(target[key], key) ? append(target[key], ...sources) : clone(target[key])
		return obj
	}, {})
	return target
}

export function recursiveAppend(target, ...sources) {
	if (!sources.length) return target
	const source = sources.shift()

	if (typeof target === 'object' && typeof source === 'object') {
		for (const key in source) {
			if (!target.hasOwnProperty(key)) target[key] = {}
			if (typeof source[key] === 'object') {
				target[key] = recursiveAppend(target[key], source[key])
			} else {
				target = append(target, { [key]: source[key] })
			}
		}
	}

	return recursiveAppend(target, ...sources)
}

export function mode(array, pref = null) {
	if (array.length === 0) return pref
	let modeMap = {}
	let modes = [array[0]]
	let maxCount = 1
	for (let i = 0; i < array.length; i++) {
		const el = array[i]
		if (modeMap[el] == null) {
			modeMap[el] = 1
		} else {
			modeMap[el]++
		}
		if (modeMap[el] > maxCount) {
			modes = [el]
			maxCount = modeMap[el]
		} else if (modeMap[el] === maxCount) {
			modes.push(el)
			maxCount = modeMap[el]
		}
	}
	if (modes.includes(pref)) {
		return pref
	}
	return modes[0]
}

export function omit(keysToOmit, obj) {
	if (typeof keysToOmit !== 'object' || !Array.isArray(keysToOmit)) keysToOmit = [keysToOmit]
	const newObj = { ...obj }
	for (const key of keysToOmit) {
		delete newObj[key]
	}
	return newObj
}

export function pick(keysToPick, obj) {
	if (typeof keysToPick !== 'object' || !Array.isArray(keysToPick)) keysToPick = [keysToPick]
	const newObj = {}
	for (const key of keysToPick) {
		newObj[key] = obj[key]
	}
	return newObj
}

export function navigate(href, target = '_self', popupOptions = {}, routerNavigateFn) {
	if (target === '_self') {
		abortController.abort(new Error('Navigation to ' + href))
		abortController = new AbortController() // XXX: needed for react-router / routerNavigateFn
		const hrefIsAbsolute = href.indexOf('://') > 0 || href.indexOf('//') === 0
		const hrefMatchesOrigin = !hrefIsAbsolute || urlOriginEquals(href, window.location.origin)
		if (hrefIsAbsolute && hrefMatchesOrigin) {
			href = '/' + keepAfter(rtrim(extractUrlHostPath(href), '/') + '/', '/')
		}
		if (!hrefMatchesOrigin || typeof routerNavigateFn !== 'function') {
			window.location.href = href
		} else {
			return routerNavigateFn(href)
		}
	} else {
		popup(null, href, target, popupOptions)
	}
	return null
}

export function popup(ref = null, path, target, features = {popup: true, resizable: true, scrollbars: true}, onOpen = null, onClose = null) {
	if (ref !== null && !ref.closed) {
		ref.focus()
	} else {
		let featuresString = ''
		for (const key in features) {
			const val = features[key]
			if (featuresString.length > 0) {
				featuresString += ','
			}
			if (typeof val === 'boolean') {
				if (val) {
					featuresString += key
				}
			} else {
				featuresString += key + '=' + val
			}
		}
		ref = window.open(path, target, featuresString)
		if (typeof onOpen === 'function') onOpen(ref)
	}
	if (ref && typeof onClose === 'function') {
		ref.onload = () => {
			ref.onbeforeunload = onClose
		}
	}
	return ref
}

export function objValOrDefault(obj, key, defaultValue) {
	if (typeof obj === 'object' && obj.hasOwnProperty(key)) return obj[key]
	return defaultValue
}

export function recalculatePeriodPrice(currentPrice, currentPeriodType, newPeriodType, periodTypeMultipliers) {
	currentPrice = parseFloat(currentPrice)
	currentPeriodType = currentPeriodType.toString()
	newPeriodType = newPeriodType.toString()

	if (!isObject(periodTypeMultipliers)) throw new TypeError('periodTypeMultipliers must be an object')
	if (!periodTypeMultipliers.hasOwnProperty(currentPeriodType)) throw new Error('unkonwn multiplier of current period type')
	if (!periodTypeMultipliers.hasOwnProperty(newPeriodType)) throw new Error('unkonwn multiplier of new period type')

	if (
		currentPeriodType === newPeriodType ||
		periodTypeMultipliers[currentPeriodType] === periodTypeMultipliers[newPeriodType]
	) return currentPrice

	const basePrice = currentPrice / periodTypeMultipliers[currentPeriodType]
	return basePrice * periodTypeMultipliers[newPeriodType]
}

export function recalculateContractPeriodPrice(currentPrice, currentPeriodType, newPeriodType) {
	const periodTypeMultipliers = {
		[CONTRACT_PERIOD_TYPE_MONTHLY.toString()]: 1,
		[CONTRACT_PERIOD_TYPE_QUARTERLY.toString()]: 3,
		[CONTRACT_PERIOD_TYPE_SEMIANNUAL.toString()]: 6,
		[CONTRACT_PERIOD_TYPE_YEARLY.toString()]: 12,
	}
	return recalculatePeriodPrice(currentPrice, currentPeriodType, newPeriodType, periodTypeMultipliers)
}

export function recalculateServicePeriodPrice(currentPrice, currentPeriodType, newPeriodType) {
	const periodTypeMultipliers = {
		[SERVICE_PERIOD_TYPE_MONTHLY.toString()]: 1,
		[SERVICE_PERIOD_TYPE_QUARTERLY.toString()]: 3,
		[SERVICE_PERIOD_TYPE_SEMIANNUAL.toString()]: 6,
		[SERVICE_PERIOD_TYPE_YEARLY.toString()]: 12,
	}
	return recalculatePeriodPrice(currentPrice, currentPeriodType, newPeriodType, periodTypeMultipliers)
}

export function recalculateSubscriptionPeriodPrice(currentPrice, currentPeriodType, newPeriodType) {
	const periodTypeMultipliers = {
		[SUBSCRIPTION_PERIOD_TYPE_ONE_TIME.toString()]: 1,
		[SUBSCRIPTION_PERIOD_TYPE_MONTHLY.toString()]: 1,
		[SUBSCRIPTION_PERIOD_TYPE_QUARTERLY.toString()]: 3,
		[SUBSCRIPTION_PERIOD_TYPE_SEMI_ANNUAL.toString()]: 6,
		[SUBSCRIPTION_PERIOD_TYPE_YEARLY.toString()]: 12,
	}
	return recalculatePeriodPrice(currentPrice, currentPeriodType, newPeriodType, periodTypeMultipliers)
}

export function recalculateProductPeriodPrice(currentPrice, currentPeriodType, newPeriodType) {
	const periodTypeMultipliers = {
		[PRODUCT_PERIOD_TYPE_ONE_TIME.toString()]: 1,
		[PRODUCT_PERIOD_TYPE_MONTHLY.toString()]: 1,
		[PRODUCT_PERIOD_TYPE_QUARTERLY.toString()]: 3,
		[PRODUCT_PERIOD_TYPE_SEMIANNUAL.toString()]: 6,
		[PRODUCT_PERIOD_TYPE_YEARLY.toString()]: 12,
	}
	return recalculatePeriodPrice(currentPrice, currentPeriodType, newPeriodType, periodTypeMultipliers)
}

export function getContractPeriodPriceFormat(periodType, locale = 'no', currency = 'kr', separator = '/') {
	const periodPriceFormats = {
		'no': {
			[CONTRACT_PERIOD_TYPE_MONTHLY.toString()]: '%s ' + currency + separator + 'mnd',
			[CONTRACT_PERIOD_TYPE_QUARTERLY.toString()]: '%s ' + currency + separator + 'kvartal',
			[CONTRACT_PERIOD_TYPE_SEMIANNUAL.toString()]: '%s ' + currency + separator + 'halvår',
			[CONTRACT_PERIOD_TYPE_YEARLY.toString()]: '%s ' + currency + separator + 'år',
		},
		'en': {
			[CONTRACT_PERIOD_TYPE_MONTHLY.toString()]: '%s ' + currency + separator + 'mo',
			[CONTRACT_PERIOD_TYPE_QUARTERLY.toString()]: '%s ' + currency + separator + 'quarter',
			[CONTRACT_PERIOD_TYPE_SEMIANNUAL.toString()]: '%s ' + currency + separator + 'half-year',
			[CONTRACT_PERIOD_TYPE_YEARLY.toString()]: '%s ' + currency + separator + 'year',
		}
	}
	if (!periodPriceFormats.hasOwnProperty(locale)) locale = 'no'
	if (!periodPriceFormats[locale].hasOwnProperty(periodType.toString())) return '%s ' + currency
	return periodPriceFormats[locale][periodType.toString()]
}

export function getServicePeriodPriceFormat(periodType, locale = 'no', currency = 'kr', separator = '/') {
	const periodPriceFormats = {
		'no': {
			[SERVICE_PERIOD_TYPE_MONTHLY.toString()]: '%s ' + currency + separator + 'mnd',
			[SERVICE_PERIOD_TYPE_QUARTERLY.toString()]: '%s ' + currency + separator + 'kvartal',
			[SERVICE_PERIOD_TYPE_SEMIANNUAL.toString()]: '%s ' + currency + separator + 'halvår',
			[SERVICE_PERIOD_TYPE_YEARLY.toString()]: '%s ' + currency + separator + 'år',
		},
		'en': {
			[SERVICE_PERIOD_TYPE_MONTHLY.toString()]: '%s ' + currency + separator + 'mo',
			[SERVICE_PERIOD_TYPE_QUARTERLY.toString()]: '%s ' + currency + separator + 'quarter',
			[SERVICE_PERIOD_TYPE_SEMIANNUAL.toString()]: '%s ' + currency + separator + 'half-year',
			[SERVICE_PERIOD_TYPE_YEARLY.toString()]: '%s ' + currency + separator + 'year',
		}
	}
	if (!periodPriceFormats.hasOwnProperty(locale)) locale = 'no'
	if (!periodPriceFormats[locale].hasOwnProperty(periodType.toString())) return '%s ' + currency
	return periodPriceFormats[locale][periodType.toString()]
}

export function getSubscriptionPeriodPriceFormat(periodType, locale = 'no', currency = 'kr', separator = '/') {
	const periodPriceFormats = {
		'no': {
			[SUBSCRIPTION_PERIOD_TYPE_ONE_TIME.toString()]: '%s ' + currency,
			[SUBSCRIPTION_PERIOD_TYPE_MONTHLY.toString()]: '%s ' + currency + separator + 'mnd',
			[SUBSCRIPTION_PERIOD_TYPE_QUARTERLY.toString()]: '%s ' + currency + separator + 'kvartal',
			[SUBSCRIPTION_PERIOD_TYPE_SEMI_ANNUAL.toString()]: '%s ' + currency + separator + 'halvår',
			[SUBSCRIPTION_PERIOD_TYPE_YEARLY.toString()]: '%s ' + currency + separator + 'år',
		},
		'en': {
			[SUBSCRIPTION_PERIOD_TYPE_ONE_TIME.toString()]: '%s ' + currency,
			[SUBSCRIPTION_PERIOD_TYPE_MONTHLY.toString()]: '%s ' + currency + separator + 'mo',
			[SUBSCRIPTION_PERIOD_TYPE_QUARTERLY.toString()]: '%s ' + currency + separator + 'quarter',
			[SUBSCRIPTION_PERIOD_TYPE_SEMI_ANNUAL.toString()]: '%s ' + currency + separator + 'half-year',
			[SUBSCRIPTION_PERIOD_TYPE_YEARLY.toString()]: '%s ' + currency + separator + 'year',
		}
	}
	if (!periodPriceFormats.hasOwnProperty(locale)) locale = 'no'
	if (!periodPriceFormats[locale].hasOwnProperty(periodType.toString())) return '%s ' + currency
	return periodPriceFormats[locale][periodType.toString()]
}

export function getProductPeriodPriceFormat(periodType, locale = 'no', currency = 'kr', separator = '/') {
	const periodPriceFormats = {
		'no': {
			[PRODUCT_PERIOD_TYPE_ONE_TIME.toString()]: '%s ' + currency,
			[PRODUCT_PERIOD_TYPE_MONTHLY.toString()]: '%s ' + currency + separator + 'mnd',
			[PRODUCT_PERIOD_TYPE_QUARTERLY.toString()]: '%s ' + currency + separator + 'kvartal',
			[PRODUCT_PERIOD_TYPE_SEMIANNUAL.toString()]: '%s ' + currency + separator + 'halvår',
			[PRODUCT_PERIOD_TYPE_YEARLY.toString()]: '%s ' + currency + separator + 'år',
		},
		'en': {
			[PRODUCT_PERIOD_TYPE_ONE_TIME.toString()]: '%s ' + currency,
			[PRODUCT_PERIOD_TYPE_MONTHLY.toString()]: '%s ' + currency + separator + 'mo',
			[PRODUCT_PERIOD_TYPE_QUARTERLY.toString()]: '%s ' + currency + separator + 'quarter',
			[PRODUCT_PERIOD_TYPE_SEMIANNUAL.toString()]: '%s ' + currency + separator + 'half-year',
			[PRODUCT_PERIOD_TYPE_YEARLY.toString()]: '%s ' + currency + separator + 'year',
		}
	}
	if (!periodPriceFormats.hasOwnProperty(locale)) locale = 'no'
	if (!periodPriceFormats[locale].hasOwnProperty(periodType.toString())) return '%s ' + currency
	return periodPriceFormats[locale][periodType.toString()]
}

export function formatPeriodPrice(price, periodPriceFormat = '%s kr') {
	price = parseFloat(price ?? 0.0)
	return periodPriceFormat.replace('%s', formatNumber(price, { precision: 2 }))
}

export function getPeriodPriceLabel(periodPriceFormat = '%s kr') {
	return periodPriceFormat.replace('%s', '').trim()
}

export function getDomainExpireDate(domain) {
	return !domain.expire_at ? null : new Date(domain.expire_at)
}

export function isDomainExpired(domain) {
	return !domain.auto_renew && nowAfterDate(getDomainExpireDate(domain))
}

export function getDomainRenewalDate(domain) {
	let renewalDate = getDomainExpireDate(domain)
	const expired = nowAfterDate(renewalDate)
	if (renewalDate !== null && expired && domain.domain_name_parts.min_register_period_y !== null && domain.auto_renew) {
		renewalDate.setFullYear(renewalDate.getFullYear() + domain.domain_name_parts.min_register_period_y)
	}
	return renewalDate
}

export function preventDefault(handlerFn) {
	return function(e, ...args) {
		if (!e.defaultPrevented) e.preventDefault()
		if (typeof handlerFn === 'function') return handlerFn.apply(this, [e].concat(args))
	}
}

export function extractHostFromUrlOrEmailAddress(url) {
	if (typeof url !== 'string') return ''
	url = stripUrlScheme(url)
	url = keepAfter(url, '@')
	url = stripUrlFragment(url)
	url = stripUrlQueryString(url)
	url = stripUrlPath(url)
	return url
}

export function isProbablyFullyQualifiedDomainName(fqdn) {
	if (typeof fqdn !== 'string') return false
	let lastDotPos = -1
	for (let i = 0; i < fqdn.length; i++) {
		if (['@', '#', '?', '/'].includes(fqdn[i])) return false
		if (fqdn[i] === '.') lastDotPos = i
	}
	return fqdn.length > 3 && lastDotPos > 0 && (fqdn.length - lastDotPos) > 2
}

export function debounce(callback, wait) {
	let timeoutId = null
	return (...args) => {
		clearTimeout(timeoutId)
		timeoutId = setTimeout(() => callback(...args), wait)
	}
}

export function hasAccessScope(user, scope, accountId = 'global') {
	return isObject(user) &&
		isObject(user.auth) &&
		isObject(user.auth.scopes) &&
		user.auth.scopes.hasOwnProperty(accountId) &&
		typeof user.auth.scopes[accountId] === 'object' &&
		Array.isArray(user.auth.scopes[accountId]) &&
		user.auth.scopes[accountId].includes(scope)
}

export function tobool(str) {
	if (typeof str !== 'string') return null
	return ['1', 'true', 'yes', 'ja'].includes(str.trim().toLowerCase())
}

export function updateQueryStringValue(key, value) {
	const currentSearchParams = new URLSearchParams(window.location.search)
	const oldValue = currentSearchParams.get(key) ?? ''
	if (value === oldValue) return

	if (typeof value === 'undefined') {
		currentSearchParams.delete(key)
	} else {
		currentSearchParams.set(key, value)
	}

	const newUrl = [window.location.pathname, currentSearchParams.toString()]
		.filter(Boolean)
		.join('?')

	window.history.replaceState(null, '', newUrl)
}

export function updateQueryParams(newParams) {
	const currentSearchParams = new URLSearchParams(window.location.search)

	let changed = false
	for (const key in newParams) {
		const value = newParams[key]
		const oldValue = currentSearchParams.get(key) ?? ''

		if (value === oldValue) continue
		changed = true

		if (typeof value === 'undefined') {
			currentSearchParams.delete(key)
		} else {
			currentSearchParams.set(key, value)
		}
	}
	if (!changed) return

	const newUrl = [window.location.pathname, currentSearchParams.toString()]
		.filter(Boolean)
		.join('?')

	window.history.replaceState(null, '', newUrl)
}