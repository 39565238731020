import React from 'react'
import DataSelect from './data_select'
import { append, handleRedirect, isObject } from '../helpers'
import { getDomainContacts } from '../api/domains'
import { contactTypeOrganization, defaultData as defaultContactData, getDomainContactHandle } from './domain_contact'
import { useNavigate } from 'react-router-dom'

export const defaultData = append(defaultContactData, { id: '_custom' })

export default function DomainContactSelect({
	disabled,
	searchOnly,
	accountId,
	publicSuffix,
	registry,
	value,
	onChange,
	options,
	provideDefaultOption,
}) {
	options = options ?? []
	provideDefaultOption = provideDefaultOption ?? false

	const routerNavigateFn = useNavigate()

	let query = {op: 'and', grp: [
		{op: 'ne', key: 'handles.handle', val: null},
	]}
	if (typeof registry === 'string' && registry.length > 0) {
		query.grp.push({op: 'eq', key: 'handles.registry', val: registry})
	}
	if (typeof publicSuffix === 'string' && publicSuffix.length > 0) {
		query.grp.push({op: 'like', key: 'handles.owned_domains.domain', val: '*.' + publicSuffix})
	}
	if (typeof accountId === 'string' && accountId.length > 0) {
		query.grp.push({op: 'eq', key: 'account_id', val: accountId})
	}

	if (provideDefaultOption) options.unshift(defaultData)

	return <DataSelect
		disabled={disabled}
		searchOnly={searchOnly}
		query={query}
		group={['handles.handle']}
		staticOptions={options}
		apiSearch={async (query, options, requestOptions) => {
			const res = await getDomainContacts(query, options, requestOptions)
			handleRedirect(res, routerNavigateFn)
			return res
		}}
		valueKey={contact => {
			const handle = getDomainContactHandle(contact, registry)
			return isObject(handle) ? handle.handle : contact.id
		}}
		renderItem={contact => {
			if (!isObject(contact) || contact.id === '_custom') return 'Egendefinert kontakt'

			const handle = getDomainContactHandle(contact, registry)
			return [
				contact.contact_type === contactTypeOrganization ? contact.organization_name : '',
				contact.name,
				contact.email_address,
				handle ? handle.handle : '',
			].filter(v => typeof v === 'string' && v.trim().length > 0).join(' - ')
		}}
		value={value}
		onChange={onChange}
	/>
}