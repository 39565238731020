import React, { createContext, useContext, useEffect, useState } from 'react'
import { Await, Navigate, Outlet, useLocation, useMatches, useNavigate, useNavigation, useRouteLoaderData } from 'react-router-dom'
import { append, hasAccessScope, isObject, omit } from '../helpers'
import Dash, { DashContentLoading } from '../components/dash'
import { defaultAuthState } from './auth'

// context value: [breadcrumbs...]
const BreadcrumbsContext = createContext()
export function useBreadcrumbs() {
	const ctx = useContext(BreadcrumbsContext)
	if (ctx === undefined) {
		throw new Error('useBreadcrumbs must be inside a BreadcrumbsProvider')
	}
	return ctx
}

// context value: [messages, setMessages]
const MessagesContext = createContext()
export function useMessages() {
	const ctx = useContext(MessagesContext)
	if (ctx === undefined) {
		throw new Error('useMessages must be inside a MessagesProvider')
	}
	return ctx
}

// context value: { ...defaultAuthState }
const AuthContext = createContext()
export function useAuth() {
	const ctx = useContext(AuthContext)
	if (ctx === undefined) {
		throw new Error('useAuth must be inside a AuthProvider')
	}
	return ctx
}

export const preloadedEntity = { current: null }
const PreloadedEntityDispatcherContext = createContext(newPreloadedEntity => preloadedEntity.current = newPreloadedEntity)
export function usePreloadedEntityDispatcher() {
	return useContext(PreloadedEntityDispatcherContext)
}

function AuthRedirectProvider({ children }) {
	const location = useLocation()
	const { state: navigationState } = useNavigation()
	const { user } = useAuth()
	const [, setMessages] = useMessages()
	const routerNavigateFn = useNavigate()

	const matches = useMatches()
	const match = matches[matches.length - 1]

	const requiresAuth = isObject(match.handle) &&
		isObject(match.handle.auth) &&
		!!match.handle.auth.required

	const isAuthenticated = isObject(user) &&
		isObject(user.auth) &&
		typeof user.auth.token === 'string' &&
		user.auth.token.length > 0

	const requiredAccessScopes = matches.flatMap(match =>
		isObject(match.handle) &&
		isObject(match.handle.auth) &&
		typeof match.handle.auth.scopes === 'object' &&
		Array.isArray(match.handle.auth.scopes)
			? match.handle.auth.scopes
			: []
	)

	// check access scopes
	const userHasRequiredAccessScopes = requiredAccessScopes.findIndex(scope =>
		!hasAccessScope(user, scope, 'global') &&
		(!isObject(user) || !isObject(user.account) || !hasAccessScope(user, scope, user.account.id))
	) === -1
	useEffect(() => {
		if (requiresAuth && !isAuthenticated) {
			routerNavigateFn('/auth?return=' + encodeURIComponent(location.pathname + location.search))
		} else if (requiresAuth && !userHasRequiredAccessScopes) {
			// redirect to previous page with message if user does not have access
			setMessages(messages => messages.concat({
				key: 'access_error_' + Math.round(new Date() / 1000).toString(),
				dismissable: true,
				type: 'error',
				icon: 'exclamation circle',
				content: 'Du har ikke tilgang til siden du forsøkte å gå til. Hvis du nylig har fått tilgang, logg ut og inn igjen (trykk på brukeren din øverst til høyre).'
			}))
			if (location.key === 'default') {
				routerNavigateFn('/')
			} else {
				routerNavigateFn(-1)
			}
		}
	}, [location, requiresAuth, isAuthenticated, userHasRequiredAccessScopes, routerNavigateFn, setMessages])

	// show loading content if redirecting or loading (and loader isn't explicitly disabled)
	if (
		(requiresAuth && (!isAuthenticated || !userHasRequiredAccessScopes)) ||
		(navigationState === 'loading' && ((isObject(match.handle) ? match.handle.loader : null) ?? true))
	) {
		return <DashContentLoading />
	}

	return children
}

function DashWithPropsFromMatches({ children }) {
	const matches = useMatches()
	const match = matches[matches.length - 1]
	const dashProps = isObject(match.handle) && isObject(match.handle.dash)
		? match.handle.dash
		: {}

	if (dashProps.disabled) return children

	return <Dash loader={isObject(match.handle) ? match.handle.loader : null} {...omit('loader', dashProps)}>
		{children}
	</Dash>
}

function MessagesProvider({ children }) {
	const { messages: initialMessages } = useAuth()
	const [messages, setMessages] = useState(initialMessages)
	useEffect(() => setMessages(messages => messages.length === 0 ? initialMessages : messages), [initialMessages])

	return <MessagesContext.Provider value={[messages, setMessages]}>
		{children}
	</MessagesContext.Provider>
}

function BreadcrumbsProvider({ children }) {
	const matches = useMatches()

	// get breadcrumbs
	let breadcrumbs = matches
		.filter(match => isObject(match.handle) && ['function', 'object'].includes(typeof match.handle.breadcrumb))
		.map((match, i, matches) => {
			const { breadcrumb } = match.handle
			let data = {}
			if (typeof breadcrumb === 'object') {
				data = breadcrumb
			} else if (typeof breadcrumb === 'function') {
				data = breadcrumb(match, i, matches)
			}
			return append({
				key: match.id,
				href: (data.link ?? true) ? match.pathname : null,
				active: false,
				content: '',
			}, omit('link', data))
		})
		.filter(breadcrumb => (typeof breadcrumb.content === 'object' && typeof breadcrumb.content.then === 'function') || (typeof breadcrumb.content === 'string' && breadcrumb.content.length > 0))
	if (breadcrumbs.length > 0) {
		breadcrumbs[breadcrumbs.length - 1].href = null
		breadcrumbs[breadcrumbs.length - 1].active = true
	}

	// set page title from breadcrumbs
	useEffect(() => {
		Promise.all(breadcrumbs.map(breadcrumb => Promise.resolve(breadcrumb.content)))
			.then(segments => {
				document.title = segments
					.reverse()
					.concat('KitSAP')
					.join(' · ')
			})
	}, [breadcrumbs])

	return <BreadcrumbsContext.Provider value={breadcrumbs}>
		{children}
	</BreadcrumbsContext.Provider>
}

export default function RootPage() {
	const { auth } = useRouteLoaderData('root')

	const matches = useMatches()
	const match = matches[matches.length - 1]

	return <BreadcrumbsProvider>
		<React.Suspense
			fallback={<AuthContext.Provider value={defaultAuthState}>
				<MessagesProvider>
					<DashWithPropsFromMatches>
						{((isObject(match.handle) ? match.handle.loader : null) ?? true) ? <DashContentLoading /> : <Outlet />}
					</DashWithPropsFromMatches>
				</MessagesProvider>
			</AuthContext.Provider>}
		>
			<Await resolve={auth}>
				{({redirect, data: auth}) => redirect !== null ? <AuthContext.Provider value={defaultAuthState}>
					<MessagesProvider>
						<DashWithPropsFromMatches>
							<DashContentLoading />
							<Navigate to={redirect} />
						</DashWithPropsFromMatches>
					</MessagesProvider>
				</AuthContext.Provider> : <AuthContext.Provider value={auth}>
					<MessagesProvider>
						<DashWithPropsFromMatches>
							<AuthRedirectProvider>
								<Outlet />
							</AuthRedirectProvider>
						</DashWithPropsFromMatches>
					</MessagesProvider>
				</AuthContext.Provider>}
			</Await>
		</React.Suspense>
	</BreadcrumbsProvider>
}