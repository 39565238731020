import React, { useEffect, useState } from 'react'
import { Segment, Container, Header, Button, Form, Placeholder } from 'semantic-ui-react'
import { emailDomainRegex, handleRedirect, isObject, navigate, preventDefault } from '../../../helpers'
import { expireDomain } from '../../../api/domains'
import { Await, useNavigate, useRouteLoaderData } from 'react-router-dom'
import { useMessages, usePreloadedEntityDispatcher } from '../../root'
import TaskExecutionLink from '../../../components/task_execution_link'

export default function DeleteDomainPage() {
	const { domainId, domain } = useRouteLoaderData('domain')
	const [, setMessages] = useMessages()
	const [domainConfirmInput, setDomainConfirmInput] = useState('')
	const [purgeDNSInput, setPurgeDNSInput] = useState(false)
	const [addParkingDNSInput, setAddParkingDNSInput] = useState(false)
	const [expiringDomain, setExpiringDomain] = useState(false)
	const [taskExecutionId, setTaskExecutionId] = useState(null)
	const preloadEntity = usePreloadedEntityDispatcher()
	const routerNavigateFn = useNavigate()

	useEffect(() => {
		Promise.resolve(domain).then(res => {
			if (isObject(res) && isObject(res.data) && !res.data.auto_renew) {
				setMessages(messages => messages.concat({
					key: 'warning_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'warning',
					icon: 'exclamation triangle',
					content: 'Domenet ' + res.data.domain + ' er allerede sagt opp',
				}))

				// preload domain data
				preloadEntity(res.data)

				// navigate to domain index page
				navigate('/domains/' + encodeURIComponent(domainId), '_self', {}, routerNavigateFn)
			}
		})
	}, [domainId, domain, setMessages, preloadEntity, routerNavigateFn])

	const handleExpireDomain = () => {
		Promise.resolve(domain).then(res => {
			if (!isObject(res) || !isObject(res.data) || (domainConfirmInput !== res.data.domain && domainConfirmInput !== res.data.ace)) return
			setExpiringDomain(true)

			expireDomain(domainId, { purge_dns: purgeDNSInput, add_parking_dns: addParkingDNSInput }).then(res => {
				handleRedirect(res, routerNavigateFn)
				const { execution_id } = res.data
				setExpiringDomain(false)
				setTaskExecutionId(execution_id)
			}).catch(e => {
				handleRedirect(e.res, routerNavigateFn)
				setMessages(messages => messages.concat({
					key: 'error_' + Math.round(new Date() / 1000).toString(),
					dismissable: true,
					type: 'error',
					icon: 'exclamation circle',
					content: 'Domenet kunne ikke settes til utløp: ' + (e && e.message ? e.message : 'Ukjent feil (sjekk JavaScript-konsollen)'),
				}))
				setExpiringDomain(false)
			})
		})
	}

	return <>
		<Segment vertical padded="very">
			<Container>
				<React.Suspense fallback={
					<Placeholder>
						<Placeholder.Header>
							<Placeholder.Line />
							<Placeholder.Line />
						</Placeholder.Header>
					</Placeholder>
				}>
					<Await resolve={domain}>
						{res => <Header size="huge">
							Si opp domene {isObject(res) && isObject(res.data) ? res.data.domain : ''}?
							{isObject(res) && isObject(res.data) && typeof res.data.ace === 'string' && res.data.ace.length > 0 ? <Header.Subheader>{res.data.ace}</Header.Subheader> : null}
						</Header>}
					</Await>
				</React.Suspense>

				{taskExecutionId === null ? null : <TaskExecutionLink id={taskExecutionId} header="Domene settes til utløp" />}

				{taskExecutionId !== null ? null : <Form onSubmit={preventDefault(() => handleExpireDomain())}>
					<Form.Input
						type="text"
						pattern={emailDomainRegex.source}
						autoComplete="off"
						label="Skriv inn domenet for å bekrefte oppsigelse"
						placeholder="example.com"
						value={domainConfirmInput}
						onChange={(e, data) => setDomainConfirmInput(data.value)}
						disabled={expiringDomain}
					/>
					<Form.Checkbox
						label="Fjern alle DNS-pekere for domene"
						checked={purgeDNSInput}
						onChange={(_, data) => setPurgeDNSInput(data.checked)}
					/>
					<Form.Checkbox
						label="Legg til DNS-pekere for domeneparkering"
						checked={addParkingDNSInput}
						onChange={(_, data) => setAddParkingDNSInput(data.checked)}
					/>
					<Form.Field>
						<Button
							as="a"
							href={'/domains/' + domainId}
							onClick={preventDefault(e => navigate('/domains/' + domainId, (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
							content="Avbryt"
							disabled={expiringDomain}
						/>
						<React.Suspense fallback={
							<Button
								negative
								content="Bekreft"
								loading
								disabled
							/>
						}>
							<Await resolve={domain}>
								{res => <Button
									type="submit"
									negative
									content="Bekreft"
									loading={expiringDomain}
									disabled={expiringDomain || !isObject(res) || !isObject(res.data) || (domainConfirmInput !== res.data.domain && (typeof res.data.ace !== 'string' || res.data.ace.length <= 0 || domainConfirmInput !== res.data.ace))}
								/>}
							</Await>
						</React.Suspense>
					</Form.Field>
				</Form>}
			</Container>
		</Segment>
	</>
}