import React, { useCallback, useMemo, useState } from 'react'
import { Header, Button, Popup, Grid, Icon, Message } from 'semantic-ui-react'
import { getForwardings } from '../../../api/mail1/forwardings'
import { formatNumber, handleRedirect, navigate, preventDefault } from '../../../helpers'
import DataTable from '../../../components/data_table'
import { useRouteLoaderData, useNavigate } from 'react-router-dom'
import { usePreloadedEntityDispatcher } from '../../root'

export default function KitmailDomainForwardingsPage() {
	const { domain } = useRouteLoaderData('kitmailDomain')
	const [dataInfo, setDataInfo] = useState(null)
	const defaultQuery = useMemo(() => ({op: 'like', key: 'source.address', val: '*@' + domain}), [domain])
	const routerNavigateFn = useNavigate()
	const preloadEntity = usePreloadedEntityDispatcher()

	const apiSearch = useCallback(async (query, options, requestOptions) => {
		const res = await getForwardings(query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		if (JSON.stringify(query) === JSON.stringify(defaultQuery)) {
			setDataInfo(res.data_info)
		}
		return res
	}, [defaultQuery, routerNavigateFn])

	return <>
		<Grid stackable>
			<Grid.Row verticalAlign="bottom">
				<Grid.Column width={10}>
					<Header size="large">Videresendinger</Header>
				</Grid.Column>
				<Grid.Column width={6} textAlign="right">
					<Button
						as="a"
						compact
						href={'/kitmail/domains/' + domain + '/forwardings/new'}
						onClick={preventDefault(e => navigate('/kitmail/domains/' + domain + '/forwardings/new', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
						color="orange"
						label={dataInfo ? formatNumber(dataInfo.count) : '0'}
						labelPosition="right"
						content={<><Icon name="add" /> Opprett ny videresending</>}
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
		<p></p>

		<Message info><Icon name="info" /> Adresser som starter med <code>@</code> er "wildcard"-videresendinger (videresend all e-post fra domenet)</Message>
		<DataTable
			columns={[
				{ key: 'source.address', text: 'Fra', width: 7, visible: true, sortable: true, searchable: true },
				{ key: 'destination.address', text: 'Til', width: 7, visible: true, sortable: true, searchable: true },
				{ key: 2, text: '', width: 2, visible: true },
			]}
			query={defaultQuery}
			defaultOrder={{'source.address': 'ASC'}}
			apiSearch={apiSearch}
			renderRow={data => [
				data.source.address,
				data.destination.address,
				<div className="text-ha-right">
					<Button.Group size="mini" compact>
						<Popup
							inverted
							content="Slett videresending"
							trigger={<Button
								as="a"
								negative
								icon="delete"
								href={'/kitmail' + (data.source.domain ? '/domains/' + data.source.domain.domain : '') + '/forwardings/' + data.source.address + '/delete'}
								onClick={preventDefault(e => {
									// preload forwarding data
									preloadEntity(data)
									// navigate to forwarding delete page
									navigate('/kitmail' + (data.source.domain ? '/domains/' + data.source.domain.domain : '') + '/forwardings/' + data.source.address + '/delete', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn)
								})}
							/>}
						/>
					</Button.Group>
				</div>,
			]}
		/>
	</>
}