import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Header, Placeholder, Button, Icon, Grid } from 'semantic-ui-react'
import { formatNumber, dateToRelativeString, append, genUUID, navigate, preventDefault, handleRedirect } from '../../../helpers'
import DataTable from '../../../components/data_table'
import { getTickets } from '../../../api/autotask/tickets'
import { getContact } from '../../../api/autotask/contacts'
import { Await, defer, useLoaderData, useNavigate, useRouteLoaderData } from 'react-router-dom'
import { getCompanies } from '../../../api/autotask/companies'
import Navigate from '../../../components/navigate'

export async function loader({ params: { accountId } }) {
	return defer({
		autotaskCompany: getCompanies([
			{'op': 'eq', 'field': 'KitCloud UUID', 'value': accountId, 'udf': true},
		], { limit: 1 }),
	})
}

const initialTicketContacts = {}

function AccountTicketsDataTable({ loading, autotaskCompany, setDataInfo }) {
	const routerNavigateFn = useNavigate()
	const [ticketContacts, setTicketContacts] = useState(initialTicketContacts)

	const renderContactName = contact => {
		if (!contact) return null
		return [contact.namePrefix, contact.firstName, contact.middleInitial, contact.lastName, contact.nameSuffix].filter(v => typeof v === 'string' && v.trim().length > 0).join(' ');
	}
	const initialTicketQuery = useMemo(() => ({op: 'eq', field: 'companyID', value: autotaskCompany !== null ? autotaskCompany.id : null}), [autotaskCompany])

	const ticketContactIDs = useRef([])
	const apiSearch = useCallback(async (query, options, requestOptions) => {
		const res = await getTickets(query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		if (JSON.stringify(query) === JSON.stringify([initialTicketQuery])) {
			setDataInfo(res.data_info)
		}
		if (res.data) {
			for (const ticket of res.data) {
				if (!ticket.contactID || ticketContactIDs.current.includes(ticket.contactID)) continue
				ticketContactIDs.current.push(ticket.contactID)
				getContact(ticket.contactID).then(res => {
					handleRedirect(res, routerNavigateFn)
					setTicketContacts(ticketContacts => append(ticketContacts, {[ticket.contactID]: res.data}))
				}).catch(e => {
					handleRedirect(e.res, routerNavigateFn)
					setTicketContacts(ticketContacts => append(ticketContacts, {[ticket.contactID]: null}))
				})
			}
		}
		return res
	}, [initialTicketQuery, setDataInfo, routerNavigateFn])

	return <DataTable
		format="autotask"
		columns={[
			{ key: 'ticketNumber', text: 'Saksnummer', visible: true, searchable: true },
			{ key: 'title', text: 'Tittel', visible: true, searchable: true },
			{ key: 'description', text: 'Beskrivelse', visible: false, searchable: true },
			{ key: 'contactID', text: 'Kontakt', visible: true },
			{ key: 'status.label', text: 'Status', visible: true },
			{ key: 'lastActivityDate', text: 'Siste aktititet', visible: true },
		]}
		defaultOrder={{'ticketNumber': 'ASC'}}
		disabled={autotaskCompany === null}
		loading={loading}
		query={initialTicketQuery}
		apiSearch={apiSearch}
		onClickRow={data => navigate('https://ww19.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=OpenTicketDetail&TicketId=' + encodeURIComponent(data.id), 'open_ticket_' + genUUID(), {}, routerNavigateFn)}
		renderRow={data => {
			return [
				data.ticketNumber,
				data.title,
				!data.contactID ? null : (ticketContacts.hasOwnProperty(data.contactID) ? renderContactName(ticketContacts[data.contactID]) : <Placeholder><Placeholder.Line /></Placeholder>),
				data.status.label,
				dateToRelativeString(data.lastActivityDate),
			]
		}}
	/>
}

export default function AccountTicketsTab() {
	const { accountId } = useRouteLoaderData('account')
	const { autotaskCompany } = useLoaderData()
	const [dataInfo, setDataInfo] = useState(null)
	const routerNavigateFn = useNavigate()

	return <>
		<React.Suspense>
			<Await resolve={autotaskCompany}>
				{({redirect}) => redirect !== null ? <Navigate to={redirect} /> : null}
			</Await>
		</React.Suspense>

		<Grid stackable>
			<Grid.Row verticalAlign="bottom">
				<Grid.Column width={10}>
					<Header size="large">Saker</Header>
				</Grid.Column>
				<Grid.Column width={6} textAlign="right">
					<Button
						as="a"
						compact
						href={'/accounts/' + encodeURIComponent(accountId) + '/tickets/new'}
						onClick={preventDefault(e => navigate('/accounts/' + encodeURIComponent(accountId) + '/tickets/new', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
						color="orange"
						label={dataInfo ? formatNumber(dataInfo.count) : '0'}
						labelPosition="right"
						disabled
						content={<><Icon name="add" /> Opprett ny sak</>}
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
		<p></p>

		<React.Suspense
			fallback={<AccountTicketsDataTable loading autotaskCompany={null} />}
		>
			<Await resolve={autotaskCompany}>
				{({data: autotaskCompany}) => <AccountTicketsDataTable
					autotaskCompany={autotaskCompany.length > 0 ? autotaskCompany[0] : null}
					setDataInfo={setDataInfo}
				/>}
			</Await>
		</React.Suspense>
	</>
}