import React, { useCallback, useState } from 'react'
import { Header, Button, Icon, Grid } from 'semantic-ui-react'
import { Await, useNavigate, useRouteLoaderData } from 'react-router-dom'
import { formatNumber, handleRedirect, navigate, preventDefault } from '../../../helpers'
import DataTable from '../../../components/data_table'
import { getAccountUsers } from '../../../api/accounts'
import Link from '../../../components/link'

function AccountUsersDataTable({ loading, account, setDataInfo }) {
	const routerNavigateFn = useNavigate()
	const apiSearch = useCallback(async (query, options, requestOptions) => {
		const res = await getAccountUsers(account.id, query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		if (query === null) {
			setDataInfo(res.data_info)
		}
		return res
	}, [account, setDataInfo, routerNavigateFn])

	return <DataTable
		columns={[
			{ key: 'firstname', text: 'Fornavn', visible: true, sortable: true, searchable: true },
			{ key: 'surname', text: 'Etternavn', visible: true, sortable: true, searchable: true },
			{ key: 'email', text: 'E-post', visible: true, sortable: true, searchable: true },
			{ key: 'phone_mobile.number', text: 'Mobiltelefon', visible: true, sortable: true, searchable: true },
			// { key: 3, text: '', visible: true },
		]}
		defaultOrder={{surname: 'ASC', firstname: 'ASC'}}
		disabled={account === null}
		loading={loading}
		apiSearch={apiSearch}
		/* onClickRow={(data, index, { ctrlKey, shiftKey }) => {
			const url = '/accounts/' + encodeURIComponent(account.id) + '/users/' + encodeURIComponent(data.id)
			const target = (ctrlKey || shiftKey) ? '_blank' : '_self'
			navigate(url, target, {}, routerNavigateFn)
		}} */
		renderRow={data => [
			data.firstname,
			data.surname,
			<Link href={'mailto:' + data.email} target="_blank">{data.email}</Link>,
			<Link href={data.phone_mobile.uri} target="_blank">{data.phone_mobile.number}</Link>,
			/* <div className="text-ha-right">
				<Button.Group size="mini" compact>
					<Popup
						inverted
						content="Endre bruker"
						trigger={<Button
							as="a"
							color="blue"
							icon="edit"
							href={'/accounts/' + encodeURIComponent(account.id) + '/users/' + encodeURIComponent(data.id) + '/edit'}
						/>}
					/>
					<Popup
						inverted
						content="Slett bruker"
						trigger={<Button
							as="a"
							negative
							icon="delete"
							href={'/accounts/' + encodeURIComponent(account.id) + '/users/' + encodeURIComponent(data.id) + '/delete'}
						/>}
					/>
				</Button.Group>
			</div> */
		]}
	/>
}

export default function AccountUsersTab() {
	const { accountId, account } = useRouteLoaderData('account')
	const [dataInfo, setDataInfo] = useState(null)
	const routerNavigateFn = useNavigate()

	return <>
		<Grid stackable>
			<Grid.Row verticalAlign="bottom">
				<Grid.Column width={10}>
					<Header size="large">Brukere</Header>
				</Grid.Column>
				<Grid.Column width={6} textAlign="right">
					<Button
						as="a"
						compact
						href={'/accounts/' + encodeURIComponent(accountId) + '/users/new'}
						onClick={preventDefault(e => navigate('/accounts/' + encodeURIComponent(accountId) + '/users/new', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
						color="orange"
						label={dataInfo ? formatNumber(dataInfo.count) : '0'}
						labelPosition="right"
						disabled
						content={<><Icon name="add" /> Opprett ny bruker</>}
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
		<p></p>

		<React.Suspense
			fallback={<AccountUsersDataTable loading account={null} />}
		>
			<Await resolve={account}>
				{({data: account}) => <AccountUsersDataTable
					account={account}
					setDataInfo={setDataInfo}
				/>}
			</Await>
		</React.Suspense>
	</>
}