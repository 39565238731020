import React from 'react'
import { Grid } from 'semantic-ui-react'

export default function AssetLabel({
	id,
	className,
	nameLabel,
	name,
	meta1Label,
	meta1Barcode,
	meta1,
	meta2Label,
	meta2Barcode,
	meta2,
	onChange
}) {
	nameLabel = nameLabel ?? 'PC-navn'
	name = name ?? ''
	meta1Label = meta1Label ?? 'Serienummer [S/N]'
	meta1Barcode = meta1Barcode ?? true
	meta1 = meta1 ?? ''
	meta2Label = meta2Label ?? 'Modellnummer [P/N]'
	meta2Barcode = meta2Barcode ?? true
	meta2 = meta2 ?? ''
	onChange = onChange ?? (({name, meta1, meta2}) => {})

	return <div className={'kit-assetlabel ' + className} id={id}>
		<div className="kit-assetlabel-inner">
			<Grid stackable columns={2}>
				<Grid.Row>
					<Grid.Column>
							<input type="text" title="Type navn" value={nameLabel} onChange={e => onChange({nameLabel: e.target.value})} className="kit-assetlabel-header" />
							<input type="text" title={nameLabel} value={name} onChange={e => onChange({name: e.target.value})} className="kit-assetlabel-text-primary text-bold" />
							<p className="kit-assetlabel-text-secondary text-bold">
								Konsept-IT Support
								<svg className="kit-assetlabel-logo" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101.91 101.91">
									<circle fill="currentColor" cx="50.96" cy="50.96" r="47.91"></circle>
									<path d="M50.96,0C22.81,0,0,22.81,0,50.96c0,28.14,22.81,50.96,50.96,50.96c28.14,0,50.96-22.81,50.96-50.96 C101.91,22.81,79.1,0,50.96,0z M73.59,40.18L53.13,53.21L44.1,42.34l21.96-10.69L73.59,40.18z M45.39,19.07l15.34-7.69L43.31,41.85 l-12.56,5.71L45.39,19.07z M33.38,80.76L25.07,69.4l5.76-20.77l8.66,12.86L33.38,80.76z M62.7,84.88L40.32,61.79l12.54-7.72 l24.09,17.1L62.7,84.88z"></path>
								</svg>
							</p>
							<p className="kit-assetlabel-header">Telefon</p>
							<p className="kit-assetlabel-text-secondary">+47 31 30 90 40</p>
							<p className="kit-assetlabel-header">E-post</p>
							<p className="kit-assetlabel-text-secondary">support@konsept-it.no</p>
					</Grid.Column>

					<Grid.Column textAlign="right" verticalAlign="bottom">
							<input type="text" title="Metadata 1 navn" value={meta1Label} onChange={e => onChange({meta1Label: e.target.value})} className="kit-assetlabel-header" />
							{meta1Barcode ? <p className="kit-assetlabel-barcode">{meta1 ? '*' + meta1 + '*' : null}</p> : null}
							<input type="text" title={meta1Label} value={meta1} onChange={e => onChange({meta1: e.target.value})} className="kit-assetlabel-text-barcode text-mono" />
							<input type="text" title="Metadata 2 navn" value={meta2Label} onChange={e => onChange({meta2Label: e.target.value})} className="kit-assetlabel-header" />
							{meta2Barcode ? <p className="kit-assetlabel-barcode">{meta2 ? '*' + meta2 + '*' : null}</p> : null}
							<input type="text" title={meta2Label} value={meta2} onChange={e => onChange({meta2: e.target.value})} className="kit-assetlabel-text-barcode text-mono" />
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	</div>
}