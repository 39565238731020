import React, { useEffect, useMemo, useState } from 'react'
import { getTickets } from '../../api/autotask/tickets'
import { getTasks } from '../../api/autotask/tasks'
import { Await, defer, useLoaderData, useNavigate } from 'react-router-dom'
import { buildQuery, isObject, navigate, preventDefault } from '../../helpers'
import Align from '../../components/align'
import { Button, Container, Header, Icon, Loader, Message, Segment } from 'semantic-ui-react'
import Logo from '../../components/logo'
import Footer from '../../components/footer'
import Messages from '../../components/messages'
import { useMessages } from '../root'

export async function loader({ params: { ticketNumber } }) {
	return defer({
		autotaskTickets: getTickets([{'op': 'eq', 'field': 'ticketNumber', 'value': ticketNumber}], { limit: 1 }),
		autotaskTasks: getTasks([{'op': 'eq', 'field': 'taskNumber', 'value': ticketNumber}], { limit: 1 })
	})
}

function TicketRedirect({ autotaskTickets, autotaskTasks }) {
	const routerNavigateFn = useNavigate()
	const autotaskTicketLink = isObject(autotaskTickets) && Array.isArray(autotaskTickets.data) && autotaskTickets.data.length > 0 ? 'https://ww19.autotask.net/Mvc/ServiceDesk/TicketDetail.mvc' + buildQuery({ TicketID: autotaskTickets.data[0].id }) : null
	const autotaskTaskLink = isObject(autotaskTasks) && Array.isArray(autotaskTasks.data) && autotaskTasks.data.length > 0 ? 'https://ww19.autotask.net/Mvc/Projects/TaskDetail.mvc' + buildQuery({ taskId: autotaskTasks.data[0].id }) : null
	const autotaskLink = autotaskTicketLink ?? autotaskTaskLink

	useEffect(() => {
		if (autotaskLink) {
			navigate(autotaskLink, '_self', {}, routerNavigateFn)
		}
	}, [autotaskLink, routerNavigateFn])

	const [showLink, setShowLink] = useState(false)
	useEffect(() => setTimeout(() => setShowLink(true), 5000), [])

	return autotaskLink ? (showLink ? <Segment secondary padded="very" textAlign="center">
		<p>Ble du ikke sendt videre? Trykk på knappen under.</p>
		<Button
			as="a"
			color="orange"
			href={autotaskLink}
			onClick={preventDefault(e => navigate(autotaskLink, (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
		>
			Gå til saken/oppgaven <Icon name="external" className="right" />
		</Button>
	</Segment> : <Segment placeholder>
		<Loader active indeterminate>Sender deg videre, vennligst vent...</Loader>
	</Segment>) : <>
		<Message attached="top" size="huge" info>
			<Header size="huge">Ukjent saksnummer</Header>
			<p>Fant ingen sak eller prosjektoppgave med saksnummeret i linken</p>
		</Message>
		<Segment attached="bottom" secondary>
			<Button type="button" onClick={preventDefault(() => routerNavigateFn(-1))}><Icon name="left arrow" /> Gå tilbake</Button>
			<Button type="button" onClick={preventDefault(() => routerNavigateFn('/'))}><Icon name="home" /> Gå hjem</Button>
		</Segment>
	</>
}

export default function TicketPage() {
	const [messages, setMessages] = useMessages()
	const { autotaskTickets, autotaskTasks } = useLoaderData()
	const promise = useMemo(() => Promise.all([autotaskTickets, autotaskTasks]), [autotaskTickets, autotaskTasks])

	return <Align>
		<Align vertical="middle" horizontal="center" verticalExpand>
			<Container className="w-500">
				<Logo />

				<Messages
					messages={messages}
					onDismiss={key => setMessages(messages => messages.filter(message => message.key !== key))}
				/>

				<React.Suspense fallback={
					<Segment placeholder>
						<Loader active indeterminate>Laster inn sak, vennligst vent...</Loader>
					</Segment>
				}>
					<Await resolve={promise}>
						{([autotaskTickets, autotaskTasks]) => <TicketRedirect
							autotaskTickets={autotaskTickets}
							autotaskTasks={autotaskTasks}
						/>}
					</Await>
				</React.Suspense>
			</Container>
		</Align>

		<Footer />
	</Align>
}