import { Response, PaginatedResponse } from '..'
import { buildQuery } from '../../helpers'

// GET /api/autotask/configuration-items
// POST /api/autotask/configuration-items/search
export async function getConfigurationItems(query = null, options = {}, requestOptions = {}) {
	if (typeof query !== 'object' || query === null) {
		return PaginatedResponse.request('/api/autotask/configuration-items' + buildQuery(options), null, requestOptions)
	}
	return PaginatedResponse.request('/api/autotask/configuration-items/search', Object.assign({ query }, options), requestOptions)
}

// POST /api/autotask/configuration-items
export async function createConfigurationItem(data, requestOptions = {}) {
	return Response.request('/api/autotask/configuration-items', data, requestOptions)
}

// GET /api/autotask/configuration-items/:id
export async function getConfigurationItem(id, requestOptions = {}) {
	return Response.request('/api/autotask/configuration-items/' + encodeURIComponent(id), null, requestOptions)
}

// PUT /api/autotask/configuration-items/:id
export async function updateConfigurationItem(id, data, requestOptions = {}) {
	return Response.request('/api/autotask/configuration-items/' + encodeURIComponent(id), data, Object.assign({ method: 'PUT' }, requestOptions))
}

// DELETE /api/autotask/configuration-items/:id/relations/:relation_id
export async function deleteConfigurationItemRelation(id, relation_id, requestOptions = {}) {
	return Response.request('/api/autotask/configuration-items/' + encodeURIComponent(id) + '/relations/' + encodeURIComponent(relation_id), null, Object.assign({ method: 'DELETE' }, requestOptions))
}

export async function getConfigurationItemUserDefinedFieldPicklistValues(requestOptions = {}) {
	return Response.request('/api/autotask/configuration-items/udf-picklist-values', null, requestOptions)
}

export const SLA_ID_DEFAULT = 1;
export const SLA_ID_BASIC = 2;
export const SLA_ID_STANDARD = 3;
export const SLA_ID_PREMIUM = 4;
export const SLA_ID_MS365_STANDARD = 5;

export const TYPE_ACCESS_POINT = 4; // Access Point
export const TYPE_ANTI_VIRUS = 7; // Anti-Virus
export const TYPE_BACKUP = 15; // Backup
export const TYPE_DOMAIN = 8; // Domain
export const TYPE_FIREWALL = 3; // Firewall
export const TYPE_DIGITAL_SIGNAGE = 16; // Infoskjerm
export const TYPE_INTERNET = 13; // Internett
export const TYPE_WEBSHOP = 12; // Nettbutikk
export const TYPE_WEBSITE = 10; // Nettside
export const TYPE_PRINTER = 5; // Printer
export const TYPE_SOFTWARE = 11; // Programvare
export const TYPE_SERVER = 2; // Server
export const TYPE_SSL_CERTIFICATE = 9; // SSL Certificate
export const TYPE_SWITCH = 17; // Switch
export const TYPE_UPS = 6; // UPS
export const TYPE_VPN = 14; // VPN
export const TYPE_WORKSTATION = 1; // Workstation

export const PERSON_TYPE_RESOURCE = 1;
export const PERSON_TYPE_CONTACT = 2;

export const SOURCE_CHARGE_TYPE_CONTRACT_COST = 1; // Contract Cost
export const SOURCE_CHARGE_TYPE_PROJECT_COST = 2; // Project Cost
export const SOURCE_CHARGE_TYPE_TICKET_COST = 3; // Ticket Cost
export const SOURCE_CHARGE_TYPE_QUOTE_ITEM = 4; // Quote Item

export const CATEGORY_STANDARD_NON_EDITABLE = 1; // Standard (non-editable)
export const CATEGORY_STANDARD_BASIC = 2; // Standard (basic)
export const CATEGORY_STANDARD = 3; // Standard
export const CATEGORY_DOMAIN = 4; // Domain
export const CATEGORY_SSL_CERTIFICATE = 5; // SSL Certificate
export const CATEGORY_WORKSTATION = 101; // Workstation
export const CATEGORY_SERVER = 102; // Server
export const CATEGORY_PRINTER = 105; // Printer
export const CATEGORY_UPS = 106; // UPS
export const CATEGORY_ANTI_VIRUS = 107; // Anti-Virus
export const CATEGORY_IVO_WEBSITE = 109; // iVo Nettside (egen plattform)
export const CATEGORY_PROGRAMVARE = 111; // Programvare
export const CATEGORY_KIT_HOSTING = 112; // KIT Hosting
export const CATEGORY_INTERNETT = 114; // Internett
export const CATEGORY_KIT_VPN = 115; // KIT VPN
export const CATEGORY_BACKUP = 116; // Backup
export const CATEGORY_IVO_WEBSITE_DUDA = 117; // iVo Nettside (Duda)
export const CATEGORY_WORDPRESS_PLUGIN = 118; // Wordpress plugin
export const CATEGORY_INFOSKJERM = 119; // Infoskjerm
export const CATEGORY_NETWORK_EQUIPMENT = 120; // Nettverksutstyr